import React, {useEffect, useState} from 'react';
import { styled } from '@mui/material/styles';
import {useDispatch, useSelector} from 'react-redux';
import {useSnackbar} from 'notistack';
import { userServices} from '../../services';
import {checkEmailExist} from '../../services/user.alreadyExist'
import {
  Button,
  Box,
  OutlinedInput,
  InputLabel,
  FormControl,
  Typography,
  CircularProgress,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Check, Clear } from "@mui/icons-material";
import ImgLauncher from "../../assets/mda_powered_by_ads.png";
import Logo from "../../assets/mda_powered_by_ads.png";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import { Copyright, useDebounce } from '../../helpers';

const PREFIX = 'UpdateEmailForm';

const classes = {
  root: `${PREFIX}-root`,
  image: `${PREFIX}-image`,
  paper: `${PREFIX}-paper`,
  logo: `${PREFIX}-logo`,
  form: `${PREFIX}-form`,
  logoSize: `${PREFIX}-logoSize`,
  marginBottom: `${PREFIX}-marginBottom`,
  emailUsed: `${PREFIX}-emailUsed`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    height: '100vh',
  },

  [`& .${classes.image}`]: {
    backgroundImage: `url(${ImgLauncher})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#303030',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
  },

  [`&.${classes.paper}`]: {
    padding: theme.spacing(8, 3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '350px',
    width: '100%',
    margin: '0 auto'
  },

  [`& .${classes.logo}`]: {
    margin: theme.spacing(1),
    backgroundColor: "transparent",
    display: "none",
    maxHeight: '250px',
    marginBottom: '2rem',
    [theme.breakpoints.only('xs')]: {
      display: 'block',
    },
  },

  [`& .${classes.form}`]: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    position: 'relative',
    top: 0,
  },

  [`& .${classes.logoSize}`]: {
    maxHeight: '200px',
  },

  [`& .${classes.marginBottom}`]: {
    width: '100%',
    marginBottom: '1.5rem',
  },

  [`& .${classes.emailUsed}`]: {
    color: '#f55c5c !important',
    marginLeft: '0.5rem',
  }

}));

export default function UpdateEmailForm(props) {
  const {register, handleSubmit, watch, errors,} = useForm({
    mode: "onChange"
  });
  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslation();

  const success = useSelector(state => state.user.success);
  const error = useSelector(state => state.user.error);
  const [isEmailUsed, setIsEmailUsed] = useState(null);
  const [isEmailSearching, setIsEmailSearching] = useState(false);
  const [sending, setSending] = useState(false);
  const debouncedSearchEmail = useDebounce(watch('newEmail'), 300);
  const dispatch = useDispatch();

  useEffect(() => {
    if (success) {
      enqueueSnackbar(success, {variant: 'success',});
    }
    if (error) {
      enqueueSnackbar(error, {variant: 'error',})
    }
  }, [dispatch, enqueueSnackbar, success, error]);

  useEffect(
    () => {
      if (debouncedSearchEmail && /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(watch('newEmail'))) {
        setIsEmailSearching(true);
        checkEmailExist(debouncedSearchEmail).then(results => {
          setIsEmailSearching(false);
          // Filter out results
          setIsEmailUsed(results);
        });
      }
    },
    [debouncedSearchEmail]
  )

  function onSubmit(data) {
    const emailSentToast = () => enqueueSnackbar(t('toasts.emailSent'), {variant: 'success',});
    const errorEmailToast = () => enqueueSnackbar(t('toasts.unknownError'), {variant: 'error',});
    setSending(true);
    userServices.sendEmailForUpdate(data.newEmail, emailSentToast, errorEmailToast)
    .then(() => setSending(false))
  }

  return (
    <Root className={classes.paper}>
      <img src={Logo} alt="ads logo" className={classes.logoSize} />
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <FormControl variant="outlined" className={classes.marginBottom}>
          <InputLabel htmlFor="newEmail">{t('account.updateEmailForm.newEmail')}</InputLabel>
          <OutlinedInput
            required
            fullWidth
            name="newEmail"
            id="newEmail"
            type="text"
            label={t('account.updateEmailForm.newEmail')}
            error={errors.hasOwnProperty('newEmail') || isEmailUsed}
            autoComplete="new-email"
            inputRef={register({
              required: true,
              validate: value => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value) 
              ? null : t('account.updateEmailForm.wrongEmailFormat')
            })}
            endAdornment={(isEmailUsed !== null || isEmailSearching) &&
              <InputAdornment position="end">
                <IconButton
                  aria-label="email availability"
                >
                  {isEmailSearching ? <CircularProgress/> : isEmailUsed ? <Clear/> : <Check/>}
                </IconButton>
              </InputAdornment>
              }
          />
          {isEmailUsed &&
            <Typography variant="caption" id="newEmail" className={classes.emailUsed}>
              {t('account.updateEmailForm.emailUsed')}
            </Typography>
          }
        </FormControl>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={sending}
        >
          {sending ? <CircularProgress size={28}/> : t('account.updateEmailForm.updateButton')}
        </Button>
        <Box mt={5}>
          <Copyright/>
        </Box>
      </form>
    </Root>
  );
}
