import React from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
// import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import BlurCircularIcon from '@mui/icons-material/BlurCircular';
import LockIcon from '@mui/icons-material/Lock';
import GeolocIcon from '../../assets/geoloc.png';
import { useNavigate } from 'react-router-dom';

const PREFIX = 'ContentCard';

const classes = {
  wrapper: `${PREFIX}-wrapper`,
  root: `${PREFIX}-root`,
  upperContainer: `${PREFIX}-upperContainer`,
  downContainer: `${PREFIX}-downContainer`,
  cardLogo: `${PREFIX}-cardLogo`,
  optionalBottomContent: `${PREFIX}-optionalBottomContent`,
  optionalIconPosition: `${PREFIX}-optionalIconPosition`,
  optionalTextPosition: `${PREFIX}-optionalTextPosition`,
  lockIcon: `${PREFIX}-lockIcon`,
  entityIcon: `${PREFIX}-entityIcon`,
  geolocIcon: `${PREFIX}-geolocIcon`,
  terminatedOverlay: `${PREFIX}-terminatedOverlay`,
  notAvailableText: `${PREFIX}-notAvailableText`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.wrapper}`]: {
    position: 'relative',
  },

  [`& .${classes.root}`]: {
    margin: '0 2px',
    backgroundColor: '#424242',
    maxWidth: '125px',
    height: '150px',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    zIndex: 90,
    position: 'relative'
  },

  [`& .${classes.upperContainer}`]: {
    borderRadius: '12px',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    zIndex: 90,
  },

  [`& .${classes.downContainer}`]: {
    color: 'white',
    padding: '0 8px 4px 8px',
    width: '125px',
    fontSize: '12px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    zIndex: 90,
  },

  [`& .${classes.cardLogo}`]: {
    width: '90%',
    height: '110px',
    objectFit: 'contain',
    borderRadius: '12px',
    zIndex: 90,
  },

  [`& .${classes.optionalBottomContent}`]: {
    backgroundColor: '#FF5252FF',
    margin: '0 2px',
    borderRadius: '12px',
    height: '97%',
    width: 'calc(100% - 4px)',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 12,
    display: 'flex',
  },

  [`& .${classes.optionalIconPosition}`]: {
    position: 'absolute',
    color: 'white',
    bottom: '4px',
    left: '4px',
    width: '18px',
    height: '18px'
  },

  [`& .${classes.optionalTextPosition}`]: {
    padding: '0 8px 4px 4px',
    position: 'absolute',
    color: 'white',
    fontSize: '12px',
    bottom: 0,
    left: '20px',
    width: '100%',
  },

  [`& .${classes.lockIcon}`]: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 99,
    color: theme.palette.primary.main
  },

  [`& .${classes.entityIcon}`]: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 999,
    color: theme.palette.primary.main
  },

  [`& .${classes.geolocIcon}`]: {
    position: 'absolute',
    width: '20px',
    height: '22px',
    bottom: '20px',
    right: 0,
    zIndex: 99,
    color: theme.palette.primary.main
  },

  [`& .${classes.terminatedOverlay}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    margin: '0 2px',
    borderRadius: '12px',
    height: '80%',
    width: 'calc(100% - 4px)',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 999,
    display: 'flex',
    textAlign: 'center'
  },

  [`& .${classes.notAvailableText}`]: {
    position: 'absolute',
    color: 'white',
    width: '100%',
    top: '45%',
    transform: 'translateY(-45%)',
    fontSize: 12,
  }
}));

const ContentCard = (props) => {

  const { t } = useTranslation();
  const history = useNavigate();

  const redirectIsVip = () => {
    if (props.isVip) {
      history(`/${props.element.uniqueKey}/vip`)
    } else {
      history(`/${props.element.uniqueKey}`)
    }
  }

  return (
    <Root
      className={classes.wrapper}
      onClick={
        props.element?.arLink
          ?
          () => props.handleBackdrop(props.element)
          :
          () => redirectIsVip()
      }
    >
      {props.element.state === 'TERMINATED' &&
        <div className={classes.terminatedOverlay}>
          <span className={classes.notAvailableText}>
            {t('home.notAvailable')}
          </span>
        </div>
      }
      {(props.isVip || props.element.securedByPassword) &&
        <LockIcon
          className={classes.lockIcon}
        />
      }
      {props.element.type === 'entity' &&
        <BlurCircularIcon
          className={classes.entityIcon}
        />
      }
      <div className={classes.root}>
        <div className={classes.upperContainer}>
          <img
            src={props.element.logoUrl || props.element.logo}
            alt={`logo-${props.element.displayName || props.element.name}`}
            className={classes.cardLogo}
          />
          {props.element.securedByPosition &&
            <img
              src={GeolocIcon}
              alt={`geoloc-icon-${props.element.displayName || props.element.name}`}
              className={classes.geolocIcon}
            />
          }
        </div>
        <div className={classes.downContainer}>
          {props.element.displayName || props.element.name}
        </div>
      </div>
      {/* {props.element?.packageId?.length > 0 && props.element.type !== 'TERMINATED' &&
        <div className={classes.optionalBottomContent}>
          <TimerOutlinedIcon
            className={classes.optionalIconPosition}
          />
          <div className={classes.optionalTextPosition}>
            {t('home.24hAccess')}
          </div>
        </div>
      } */}
    </Root>
  );
}

export default ContentCard;