export function checkLoginExist(login) {
  const requestOptions = {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
  };

  return fetch(`${process.env.REACT_APP_API_URL}/users/find?login=${login}`, requestOptions).then(handleResponse);
}

export function checkEmailExist(email) {
  const requestOptions = {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
  };

  return fetch(`${process.env.REACT_APP_API_URL}/users/find?email=${email}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {

  return response.json().then(res => {
    if (response.status === 200) {
      return res.hasOwnProperty('id');
    } else {
      const error = (res && res.message) || response.statusText;
      return Promise.reject(error);
    }
  });
}
