import React, { useCallback, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Typography,
  CircularProgress,
  Box,
} from '@mui/material';
import { useTranslation } from "react-i18next";
import { Presentation } from "./steps/Presentation";
import { ColorlibConnector, ColorlibStepIcon, ColorlibStepIconVip } from "./steps/ColorLibStep";
import MyStory from "./steps/Story";
import Informations from "./steps/Informations";
import { useDispatch, useSelector } from "react-redux";
import Conditions from "./steps/Conditions";
import { campaign } from "../../constants";
import CodeVip from "./steps/CodeVip";
import Footer from "../footer/Footer";
import Loading from "../loader/Loading";
import { save } from '../../reducers/campaigns/campaignsThunks';
import { resetCampaign } from '../../reducers/campaigns/campaignsSlice';
import { useSnackbar } from 'notistack';

const PREFIX = 'Landing';

const classes = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`,
  actionsContainer: `${PREFIX}-actionsContainer`,
  resetContainer: `${PREFIX}-resetContainer`,
  instructions: `${PREFIX}-instructions`,
  instructionsWithoutMargin: `${PREFIX}-instructionsWithoutMargin`,
  svgIcon: `${PREFIX}-svgIcon`,
  backgroundMobile: `${PREFIX}-backgroundMobile`,
  stepperMobile: `${PREFIX}-stepperMobile`,
  participationOk: `${PREFIX}-participationOk`,
  participationKo: `${PREFIX}-participationKo`,
  loadingContainer: `${PREFIX}-loadingContainer`,
  mobileImageSize: `${PREFIX}-mobileImageSize`,
  //
  rootDesktop: `${PREFIX}-rootDesktop`,
  contentRoot: `${PREFIX}-contentRoot`,
  contentContainer: `${PREFIX}-contentContainer`,
  contentLoading: `${PREFIX}-contentLoading`,
  contentButtonsContainer: `${PREFIX}-contentButtonsContainer`,
  errorContainer: `${PREFIX}-errorContainer`,
  desktopStepContentContainer: `${PREFIX}-desktopStepContentContainer`,
  progressContainer: `${PREFIX}-progressContainer`,
  rootProgress: `${PREFIX}-rootProgress`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    width: '100%',
    paddingTop: '1rem',
  },
  [`& .${classes.progressContainer}`]: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    marginTop: '5rem',
  },
  [`& .${classes.button}`]: {
    [theme.breakpoints.down('lg')]: {
      marginTop: theme.spacing(1),
    },
    marginRight: theme.spacing(1),
  },
  [`& .${classes.actionsContainer}`]: {
    [theme.breakpoints.down('lg')]: {
      marginBottom: theme.spacing(2),
    }
  },
  [`& .${classes.resetContainer}`]: {
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(3),
    }
  },
  [`& .${classes.instructionsWithoutMargin}`]: {
    textAlign: "center",
    fontWeight: "bold",
    minHeight: "250px",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "linear-gradient(black, rgb(66 66 66 / 90%), rgb(66 66 66 / 90%), #424242f2) !important",
  },
  [`& .${classes.svgIcon}`]: {
    fill: '#424242',
  },
  [`& .${classes.backgroundMobile}`]: {
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  [`& .${classes.stepperMobile}`]: {
    background: "linear-gradient(black, rgb(66 66 66 / 90%), rgb(66 66 66 / 90%), #424242f2) !important",
  },
  [`& .${classes.participationOk}`]: {
    color: "#CEB673",
    fontWeight: 600,
  },
  [`& .${classes.participationKo}`]: {
    color: "#f55c5c",
    fontWeight: 600,
    marginBottom: '1rem',
  },
  [`& .${classes.loadingContainer}`]: {
    flex: 0.8,
    minHeight: "300px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  [`& .${classes.errorContainer}`]: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '250px',
  },
  [`& .${classes.mobileImageSize}`]: {
    width: '100%',
  },
}));

const RootDesktop = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.rootDesktop}`]: {
    width: '100%',
    paddingTop: '1rem',
    minWidth: '555px',
  },
  [`& .${classes.contentRoot}`]: {
    minHeight: '500px',
    display: 'flex',
  },
  [`& .${classes.contentContainer}`]: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  [`& .${classes.contentLoading}`]: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  [`& .${classes.contentButtonsContainer}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '1rem',
  },
  [`& .${classes.button}`]: {
    [theme.breakpoints.down('lg')]: {
      marginTop: theme.spacing(1),
    },
    marginRight: theme.spacing(1),
  },
  [`& .${classes.instructions}`]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: "center",
    fontWeight: "bold",
    minHeight: "250px",
    position: "relative"
  },
  [`& . ${classes.progressContainer}`]: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    marginTop: '5rem',
  },
  [`& .${classes.participationOk}`]: {
    color: "#CEB673",
    fontWeight: 600,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, 50%)',
    width: '100%',
  },
  [`& .${classes.participationKo}`]: {
    color: "#f55c5c",
    fontWeight: 600,
    position: 'relative',
    marginBottom: '1rem',
  },
  [`& .${classes.errorContainer}`]: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '500px',
  },
  [`& .${classes.desktopStepContentContainer}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    zIndex: '99',
    '& > div': {
      width: '100%',
    },
  },

}));

const RootProgress = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.rootProgress}`]: {
    marginBottom: '2rem',
  },
}));

function CircularProgressWithLabel(props) {
  return (
    <RootProgress>
      <Box position="relative" display="inline-flex" className={classes.rootProgress}>
        <CircularProgress variant="determinate" {...props} size={65} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="caption" component="div" color="textSecondary">
            {
              `${isNaN(Math.round(props.value)) ?
                0
                :
                Math.round(props.value,)}%`
            }
          </Typography>
        </Box>
      </Box>
    </RootProgress>
  );
}

function Landing(props) {
  const isXs = useMediaQuery('(max-width:600px)');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = useState(0);
  const [disableButtons, setDisableButtons] = useState(false);
  const [cgu, setCgu] = useState(false);
  const [isVip, setVip] = useState(false);
  const loggedIn = useSelector((state) => state.user.loggedIn);
  const userCampaign = useSelector((state) => state.campaign.userData);
  const participation = useSelector((state) => state.campaign.save);
  const reason = useSelector((state) => state.campaign.reason);
  const [loading, setLoading] = useState(true);
  const [role, setRole] = useState('FAN');
  const [stepForm, setStepForm] = useState(2);
  const [savingProgress, setSavingProgress] = useState(0);

  useEffect(() => {
    const pathname = window.location.pathname;
    const pathNames = pathname.substring(1).split('/');
    if (pathNames && pathNames.length > 1) {
      setRole(pathNames[1]);
    }
    setVip(pathname.indexOf('vip') > 0);
    if (pathname.indexOf('vip') > 0) setStepForm(3);
  }, []);

  const toggleButton = (value) => {
    setDisableButtons(value);
  }

  const activeCgu = (value) => {
    setCgu(value);
  }

  const getSteps = useCallback(() => {
    if (isVip) return [t('landing.presentation'), t('landing.code-vip'), t('landing.story'), t('landing.information'), t('landing.validation')];
    return [t('landing.presentation'), t('landing.story'), t('landing.information'), t('landing.validation')];
  }, [isVip, t]);

  const getStepContentVip = useCallback((conf) => {
    return [
      <Presentation description={conf.description} name={conf.name} />,
      <CodeVip conf={conf} nextButton={toggleButton} />,
      <MyStory conf={conf} disableButtons={toggleButton} />,
      <Informations conf={conf} disableButtons={toggleButton} nextStep={activeStep !== 2} />,
      <Conditions conf={conf} activateCgu={activeCgu} />
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.conf]);

  const getStepContent = useCallback((conf) => {
    return [
      <Presentation description={conf.description} name={conf.name} />,
      <MyStory conf={conf} disableButtons={toggleButton} />,
      <Informations conf={conf} disableButtons={toggleButton} nextStep={activeStep !== 2} />,
      <Conditions conf={conf} activateCgu={activeCgu} />,
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.conf])

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setDisableButtons(false);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    dispatch(resetCampaign())
  };

  const handleFinish = async () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    try {
      const thunk = await dispatch(save({ userCampaign, role: role || 'FAN', uniqueKey: props.conf.uniqueKey, handleProgress: setSavingProgress }));
      if (thunk.meta.requestStatus === 'rejected')
        throw new Error('offline')
    } catch {
      enqueueSnackbar(t('toasts.getInfosError'), { variant: 'error' });
    }
  }

  function imageLoaded() {
    setLoading(false);
  }

  if (isXs) {
    const steps = getSteps();
    const content = isVip ? getStepContentVip(props.conf) : getStepContent(props.conf);
    return (
      <Root>
        <div className={classes.root}>
          <img src={props.conf.headerUrl || props.conf.imageHeader} className={classes.mobileImageSize} alt="header"
            onLoad={imageLoaded} onError={imageLoaded} />
          {loading ?
            <div className={classes.loadingContainer}>
              <CircularProgress />
            </div>
            :
            <div className={classes.backgroundMobile} style={{ backgroundImage: `url("${props.conf.imageFormUrl || props.conf.imageForm}")` }}>
              {!participation &&
                <Stepper className={classes.stepperMobile} activeStep={activeStep} orientation="vertical">
                  {steps.map((label, index) => (
                    <Step key={`step-${index + 1}-${label}`}>
                      <StepLabel StepIconComponent={isVip ? ColorlibStepIconVip : ColorlibStepIcon}>{label}</StepLabel>
                      <StepContent>
                        {content[index]}
                        <div className={classes.actionsContainer}>
                          <div>
                            <Button
                              disabled={activeStep === 0}
                              onClick={handleBack}
                              className={classes.button}
                            >
                              {t('common.previous')}
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={activeStep === steps.length - 1 ? handleFinish : handleNext}
                              className={classes.button}
                              disabled={disableButtons || (!loggedIn && activeStep === stepForm) || (activeStep === steps.length - 1 && !cgu)}
                            >
                              {activeStep === steps.length - 1 ? t('common.finish') : t('common.next')}
                            </Button>
                          </div>
                        </div>
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
              }
              {activeStep === steps.length &&
                <div className={classes.instructionsWithoutMargin}>
                  <div>
                    {participation === 'IN_PROGRESS' &&
                      <div className={classes.progressContainer}>
                        <CircularProgressWithLabel value={savingProgress} />
                        <Typography variant="h6">{t("landing.saving")}</Typography>
                      </div>
                    }
                    {participation === 'OK' &&
                      <Typography className={classes.participationOk}>{isVip ? t('landing.vip.videoSentVIP') : t('landing.videoSent')}</Typography>
                    }
                    {participation === 'KO' &&
                      <div className={classes.errorContainer}>
                        {reason === campaign.CAMPAIGN_USER_ALREADY_EXISTS ?
                          <Typography className={classes.participationKo}>{t('landing.storyAlreadySent')}</Typography>
                          :
                          <Typography className={classes.participationKo}>{t('landing.vip.unknownError')}</Typography>
                        }
                        <Button onClick={handleReset} className={classes.button}>
                          {t('common.back')}
                        </Button>
                      </div>
                    }
                  </div>
                </div>
              }
              <Footer />
            </div>
          }
        </div>
      </Root>
    );
  } else {
    const steps = getSteps();
    const content = isVip ? getStepContentVip(props.conf) : getStepContent(props.conf);
    return (
      <RootDesktop>
        <div className={classes.rootDesktop}>
          {!participation &&
            <Stepper
              alternativeLabel
              activeStep={activeStep}
              connector={<ColorlibConnector />}
            >
              {steps.map((label, index) => (
                <Step key={`step-${index + 1}-${label}`}>
                  <StepLabel StepIconComponent={isVip ? ColorlibStepIconVip : ColorlibStepIcon}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          }
          <div>
            {activeStep === steps.length ? (
              <div>
                <div className={classes.instructions}>
                  {participation === 'IN_PROGRESS' &&
                    <div className={classes.progressContainer}>
                      <CircularProgressWithLabel value={savingProgress} />
                      <Typography variant="h6">{t("landing.saving")}</Typography>
                    </div>
                  }
                  {participation === 'OK' &&
                    <Typography className={classes.participationOk}>
                      {isVip ? t('landing.vip.videoSentVIP') : t('landing.videoSent')}
                    </Typography>
                  }
                  {participation === 'KO' &&
                    <div className={classes.errorContainer}>
                      {reason === campaign.CAMPAIGN_USER_ALREADY_EXISTS ?
                        <Typography className={classes.participationKo}>{t('landing.storyAlreadySent')}</Typography>
                        :
                        <Typography className={classes.participationKo}>{t('landing.vip.unknownError')}</Typography>
                      }
                      <Button onClick={handleReset} className={classes.button} variant="outlined">
                        {t('common.back')}
                      </Button>
                    </div>
                  }
                </div>
              </div>
            ) : (
              <div>
                <div className={classes.contentRoot}>
                  <div className={classes.contentContainer}>
                    <img
                      src={props.conf.imageFormUrl || props.conf.imageForm}
                      style={{
                        margin: activeStep === 0 ? '0 auto' : 'inherit',
                        position: activeStep > 0 && 'absolute',
                        opacity: activeStep > 0 && 0.2,
                        top: activeStep > 0 && '50%',
                        left: activeStep > 0 && '50%',
                        transform: activeStep > 0 && 'translate(-50%, -50%)',
                        width: activeStep > 0 && '100%',
                        height: activeStep > 0 ? '100%' : '300px',
                        objectFit: activeStep > 0 && 'contain',
                      }}
                      alt="image form"
                      onLoad={imageLoaded}
                      onError={imageLoaded}
                    />
                    {loading ?
                      <div className={classes.contentLoading}>
                        <CircularProgress />
                      </div>
                      :
                      <div className={classes.desktopStepContentContainer}>
                        {content[activeStep]}
                      </div>
                    }
                  </div>
                </div>
                <div className={classes.contentButtonsContainer}>
                  <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                    {t('common.back')}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={activeStep === steps.length - 1 ? handleFinish : handleNext}
                    className={classes.button}
                    disabled={disableButtons || (!loggedIn && activeStep === stepForm) || (activeStep === steps.length - 1 && !cgu)}
                  >
                    {activeStep === steps.length - 1 ? t('common.finish') : t('common.next')}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </RootDesktop>
    );
  }
}

export default Landing;
