import React, { useEffect } from 'react';
import { useImmer } from "use-immer";
import { styled } from '@mui/material/styles';
import './mail-validation.css';
import Header from '../../components/header/Header';
import { useSnackbar } from 'notistack';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import { Container, Paper } from "@mui/material";
import { useParams } from 'react-router-dom';

const PREFIX = 'MailValidation';

const classes = {
  paperStyle: `${PREFIX}-paperStyle`,
  backgroundStyle: `${PREFIX}-backgroundStyle`,
  messageStyle: `${PREFIX}-messageStyle`,
}

const StyledDiv = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.paperStyle}`]: {
    zIndex: 999,
    padding: '0',
    maxWidth: 'inherit',
  },
  [`& .${classes.backgroundStyle}`]: {
    width: '100%',
    minHeight: '200px',
    position: 'relative',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  [`& .${classes.messageStyle}`]: {
    position: 'relative',
    bottom: '10%',
  },
}));

const MailValidation = () => {

  const { key, name } = useParams()
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation()
  const [data, setData] = useImmer({

    backgroundImage: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAA1BMVEUAAACnej3aAAAASElEQVR4nO3BgQAAAADDoPlTX+AIVQEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADwDcaiAAFXD1ujAAAAAElFTkSuQmCC",
    pathname: '',
    accountValidated: false,
    message: '',
    conf: {},
  })

  useEffect(() => {
    setData(data => {
      data.pathname = key;
    });
    const lang = navigator.language.substr(0, 2);
    fetch(`${process.env.REACT_APP_API_URL}/campaigns/configuration?lang=${lang}&path=${name}`, {
      method: "GET",
      mode: "cors",
    }).then((response) => {
      response.json().then((conf) => {
        // });});
        let tmp = {};
        tmp.imageForm = conf.imageForm;
        tmp.imageHeader = conf.imageHeader;
        tmp.headerUrl = conf.headerUrl;
        tmp.targetLabel = conf.targetLabel;
        setData(data => {
          data.conf = tmp;
        })
      });
    });

    setTimeout(() => fetch(`${process.env.REACT_APP_API_URL}/campaign-user/mail-validation/${key}`, {
      method: "GET",
      mode: "cors",
    }).then(response => {
      switch (response.status) {
        case 200:
          setData(data => {
            data.validated = true;
          });
          enqueueSnackbar(t("landing.mailValidation.participationValidated"), {
            variant: 'success',
            autoHideDuration: 3000
          });
          setData(data => {
            data.message = t("landing.mailValidation.participationValidated");
          })
          break;
        case 406:
          handleError(t("landing.mailValidation.incorrectKey"));
          break;
        case 409:
          handleError(t("landing.mailValidation.alreadyValidated"));
          break;
        default:
          handleError(t("landing.mailValidation.unknownError"));
          break;
      }
    }).catch((error) => {
      console.error('Error:', error);
      enqueueSnackbar(t("landing.mailValidation.unknownError"), {
        variant: 'error',
        autoHideDuration: 3000
      });
      setData(data => {
        data.message = t("landing.mailValidation.unknownError");
      })
    })
      , 2000);
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleError(msg) {
    enqueueSnackbar(msg, { variant: 'error', autoHideDuration: 3000 });
    setData(data => {
      data.message = msg;
    });
  }

  return (
    <StyledDiv>
      <Header imageHeader={data.conf.headerUrl || data.conf.imageHeader} />
      <Paper elevation={5} maxWidth="sm" component={Container} className={classes.paperStyle}>
        {
          data.validated || data.message ?
            <div className="mail-validation-message">
              <div className="mail-validation-informations-container">
                <div
                  className={classes.backgroundStyle}
                  style={{
                    backgroundImage: `url(https://picsum.photos/2000/1080)`,
                    // backgroundImage: `url(${data.conf.imageFormUrl || data.conf.imageForm})`,
                  }}
                >
                </div>
              </div>
              <div className={classes.messageStyle}>
                {data.message}
              </div>
            </div>
            :
            <div className="mail-validation-message">
              <CircularProgress size="3rem" />
              <p>{t("landing.mailValidation.validatingParticipation")}</p>
            </div>
        }
      </Paper>
    </StyledDiv>
  )
}


export default MailValidation;
