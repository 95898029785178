import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { closeModal } from "../../reducers/campaigns/campaignsSlice"
import { resetError } from "../../reducers/user/userSlice"
import AuthForms from "../forms/AuthForms"
import ResponsiveDialogManager from "../responsiveDialogManager/ResponsiveDialogManager"

const ModalManager = () => {

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const modalInfo = useSelector(state => state.campaign.modalType)

  const handleClose = (close = true) => {
    if (close)
      dispatch(closeModal());
    dispatch(resetError());
  };

  return (
    <>
      {modalInfo === "login" || modalInfo === "register" ?
        <ResponsiveDialogManager
          open={modalInfo ? true : false}
          renderer={
            <AuthForms mode={modalInfo} handleCloseModal={handleClose} />
          }
          title={t('account.welcomeMessage')}
          onClose={handleClose} />
        :
          null
      }
    </>
  )

}

export default ModalManager