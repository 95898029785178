import { Link, Typography } from "@mui/material";

export const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link href={'http://artdesignstory.fr'} underline="hover">
        ArtDesignStory
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}