import {authHeader} from "../helpers";

export function getHomePage() {
    const requestOptions = {
      method: 'GET',
    };
      return fetch(`${process.env.REACT_APP_GATEWAY_URL}/page`, requestOptions)
      .then(res => {
        if (res.status < 300) {
          return res.json();
        } else {
          const error = (res && res.message) || res.statusText;
          return Promise.reject(error);
        }
      });
  }

  
  export function getEntityExpariences(entityId) {
    const requestOptions = {
      method: 'GET',
      headers: {...authHeader()},
    };
      return fetch(`${process.env.REACT_APP_GATEWAY_URL}/public/entity/${entityId}/full`, requestOptions)
      .then(res => {
        if (res.status < 300) {
          return res.json();
        } else {
          const error = (res && res.message) || res.statusText;
          return Promise.reject(error);
        }
      });
  }