import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import StepConnector from "@mui/material/StepConnector";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import LockIcon from "@mui/icons-material/LockOutlined";
import VideoLibraryIcon from "@mui/icons-material/VideoLibraryOutlined";
import ContactMailIcon from "@mui/icons-material/ContactMailOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircleOutlined";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg, rgb(42 42 42 / 1) 10%, rgb(211 175 95) 100%, rgb(211 175 95) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg, rgb(42 42 42 / 1) 10%, rgb(211 175 95) 100%, rgb(211 175 95) 100%)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(42 42 42 / 1) -5%, rgb(211 175 95) 50%, rgb(211 175 95) 100%);',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(42 42 42 / 1) -5%, rgb(211 175 95) 50%, rgb(211 175 95) 100%);',
  },
  svgIcon: {
    fill: '#424242',
  }
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const {active, completed} = props;

  const icons = {
    1: <InfoIcon className={classes.svgIcon}/>,
    2: <VideoLibraryIcon className={classes.svgIcon}/>,
    3: <ContactMailIcon className={classes.svgIcon}/>,
    4: <CheckCircleIcon className={classes.svgIcon}/>,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

function ColorlibStepIconVip(props) {
  const classes = useColorlibStepIconStyles();
  const {active, completed} = props;

  const icons = {
    1: <InfoIcon className={classes.svgIcon}/>,
    2: <LockIcon className={classes.svgIcon}/>,
    3: <VideoLibraryIcon className={classes.svgIcon}/>,
    4: <ContactMailIcon className={classes.svgIcon}/>,
    5: <CheckCircleIcon className={classes.svgIcon}/>,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

export {ColorlibStepIcon, ColorlibStepIconVip, ColorlibConnector};
