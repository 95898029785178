import { configureStore } from '@reduxjs/toolkit'
import userSlice from '../reducers/user/userSlice';
import campaignsSlice from '../reducers/campaigns/campaignsSlice';

export const store = configureStore({
  reducer: {
    campaign: campaignsSlice,
    user: userSlice,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})