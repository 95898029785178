import axios from "axios";
import {authHeader} from "../helpers";

export const campaignService = {
  get,
  checkCode,
  save,
};

function get(lang, pathNameToSend, isVip) {
  return fetch(`${process.env.REACT_APP_API_URL}/campaigns/configuration?lang=${lang}&path=${pathNameToSend}${isVip}`, {
    method: "GET",
    mode: "cors",
    headers: {...authHeader(false)},
  });
}

function checkCode(code, uniqueKey) {
  return fetch(`${process.env.REACT_APP_API_URL}/campaign/code`, {
    method: "POST",
    mode: "cors",
    headers: {...authHeader(false)},
    body: JSON.stringify({
      code,
      uniqueKey,
    })
  });
}

function save(uniqueKey, formData, handleProgress) {

  const config = {
    headers: {...authHeader(true)},
    mode: "cors",
    onUploadProgress: (progressEvent) => {
    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        //do something with the percentCompleted
        //I used an observable to pass the data to a component and subscribed to it, to fill the progressbar
        handleProgress(percentCompleted);
      }
    }

  return axios.post(`${process.env.REACT_APP_API_URL}/campaigns/${uniqueKey}/usercampaign`, formData, config)

  // return fetch(`${process.env.REACT_APP_API_URL}/campaigns/${uniqueKey}/usercampaign`, {
  //   method: "POST",
  //   mode: "cors",
  //   body: formData,
  //   headers: {...authHeader(true)},
  // })
}
