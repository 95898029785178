import React, { useCallback, useEffect, useState } from 'react';
import './landing.css';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from '../../components/header/Header';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import Landing from "../../components/landing/Landing";
import { useDispatch, useSelector } from "react-redux";
import ResponsiveDialogManager from "../../components/responsiveDialogManager/ResponsiveDialogManager";
import Countdown from "react-countdown";
import { useSnackbar } from "notistack";
import Loading from "../../components/loader/Loading";
import { useNavigate } from 'react-router-dom';
import { getCampaign } from '../../reducers/campaigns/campaignsThunks';

const PREFIX = 'Campaign';

const classes = {
  endedImage: `${PREFIX}-endedImage`,
  endedText: `${PREFIX}-endedText`,
  //
  notStartedRoot: `${PREFIX}-notStartedRoot`,
  notStartedImage: `${PREFIX}-notStartedImage`,
  notStartedContainer: `${PREFIX}-notStartedContainer`,
  notStartedText: `${PREFIX}-notStartedText`,
  notStartedCountDown: `${PREFIX}-notStartedCountDown`,
  //
  mainContainer: `${PREFIX}-mainContainer`,
  loaderContainer: `${PREFIX}-loaderContainer`
};

const EndedCampaign = styled('div')(({ theme }) => {
  return ({
    [`& .${classes.endedImage}`]: {
      width: '100%',
      marginTop: '2rem',
    },
    [`& .${classes.endedText}`]: {
      width: '100%',
      minHeight: '200px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }
  })
});

const NotStartedCampaign = styled('div')(({ theme }) => {
  return ({
    [`& .${classes.notStartedRoot}`]: {
      width: '100%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    [`& .${classes.notStartedImage}`]: {
      width: '100%',
      marginTop: '2rem'
    },
    [`& .${classes.notStartedContainer}`]: {
      width: '100%',
      minHeight: '200px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    [`& .${classes.notStartedText}`]: {
      margin: '1rem',
    },
    [`& .${classes.notStartedCountDown}`]: {
      color: 'white',
      textAlign: 'center',
    },
  })
});


const RootContainer = styled('div')(({ theme }) => {
  return ({
    [`& .${classes.mainContainer}`]: {
      minHeight: 'calc(100vh - 230px - 56px)',
      padding: '0 0',
    },
    [`& .${classes.loaderContainer}`]: {
      width: '100%',
      position: 'absolute',
      top: '50%',
    }
  })
});

function LandingPage() {
  const isXs = useMediaQuery('(max-width:600px)');
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const { t } = useTranslation();
  const history = useNavigate();
  const conf = useSelector((state) => state.campaign.campaignInfos);
  const fetched = useSelector((state) => state.campaign.fetched);
  const fetchError = useSelector((state) => state.campaign.fetchError);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpenAlert(true);
  };

  function handleConfirmClose() {
    setOpenAlert(false);
    setOpen(false);
    history('/');
  }

  function handleCancelClose() {
    setOpenAlert(false);
  }

  const handleGetCampaign = async () => {
    const pathname = window.location.pathname;
    const lang = navigator.language.substr(0, 2);
    try {
      const thunk = await dispatch(getCampaign({ lang, pathname }));
      if (thunk.meta.requestStatus === 'rejected')
        throw new Error('offline')
    } catch {
      enqueueSnackbar(t('toasts.getInfosError'), { variant: 'error' });
    }
  }

  useEffect(() => {
    handleGetCampaign();
  }, []);

  useEffect(() => {
    if (fetchError) {
      setLoading(false);
      enqueueSnackbar(t("toasts.campaignDoesNotExist"), { variant: "error" });
      history('/');
    }
    if (conf && conf.name) {
      setTitle(() => {
        if (window.location.pathname.indexOf("vip") >= 0) return `${t('landing.titleVip')} ${conf.name}`;
        return `${t('landing.title')} ${conf.name}`;
      });
      setTimeout(() => {
        setLoading(false);
        if (!checkRouteWithConfig()) {
          setLoading(false);
          enqueueSnackbar(t("toasts.campaignDoesNotExist"), { variant: "error" });
          history('/');
        } else {
          handleClickOpen();
        }
      }, 500);
    }
  }, [fetched, fetchError]);

  function checkRouteWithConfig() {
    const paths = window.location.pathname.split("/").filter((el) => el);
    if ((paths.length > 1 && window.location.pathname.indexOf("vip") >= 0 && !conf.vip) || (paths.length === 1 && !conf.fan)) return false;
    return true;
  }

  const renderCountDown = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <span>{t('landing.start-soon')}</span>;
    } else {
      // Render a countdown
      return <span>{days} {t('landing.countdown.days')}, {hours} {t('landing.countdown.hours')} {minutes} {t('landing.countdown.minuts')} {seconds} {t('landing.countdown.seconds')}</span>;
    }
  };

  const renderer = useCallback(() => {
    switch (conf.state) {
      case 'ON_GOING':
        return <Landing conf={conf} />;
      case 'NOT_STARTED':
        return <NotStartedCampaign>
        <div className={classes.notStartedRoot}>
          {isXs &&
            <img src={conf.headerUrl || conf.imageHeader} className={classes.notStartedImage} alt={title} />}
          <div className={classes.notStartedContainer}>
            <div className={classes.notStartedText}>
              {t('landing.unstartedCampaign')}
            </div>
            <h2 className={classes.notStartedCountDown}>
              <Countdown date={conf.startDate} renderer={renderCountDown} />
            </h2>
          </div>
        </div>
      </NotStartedCampaign>
      case 'TERMINATED':
      case 'ARCHIVED':
        return <EndedCampaign>
          {isXs &&
            <img className={classes.endedImage} src={conf.headerUrl || conf.imageHeader} alt={title} />}
          <div className={classes.endedText}>
            {t('landing.campaignOver')}
          </div>
        </EndedCampaign>
    }
  }, [conf])

  return (
    <RootContainer>
      <div className={classes.mainContainer}>
        {loading ?
          <div className={classes.loaderContainer}>
            <Loading message={t("landing.loading")} />
          </div>
          :
          <div>
            <Header imageHeader={conf ? conf.headerUrl || conf.imageHeader : ''} />
            {conf && conf.uniqueKey &&
              <ResponsiveDialogManager
                renderer={renderer()}
                disableGutters
                open={open}
                onClose={handleClose}
                title={title}
                disableBackdrop={true}
                disableEscapeKey={true}
              />
            }
          </div>
        }
        <Dialog
          open={openAlert}
          onClose={handleCancelClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{t("landing.exit.title")}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t("landing.exit.description")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleConfirmClose} variant="contained" color="primary">
              {t("landing.exit.confirm")}
            </Button>
            <Button onClick={handleCancelClose} color="primary" autoFocus>
              {t("landing.exit.back")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </RootContainer>
  );
}

export default LandingPage;
