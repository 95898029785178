import React from 'react';
import { styled } from '@mui/material/styles';
import {Container} from "@mui/material";
import notFoundGif from '../assets/404.gif';

const PREFIX = 'NotFound';

const classes = {
  imgSize: `${PREFIX}-imgSize`
};

const NotFoundContainer = styled(Container)(({theme}) => {
  return ({
  [`& .${classes.imgSize}`]: {
    width: '100%',
    height: 'calc(100vh - 286px)',
    objectFit: 'contain',
  }
})
});

const NotFound = () => {
  return (
    <NotFoundContainer disableGutters>
      <img src={notFoundGif} alt="404 not found" className={classes.imgSize} />
    </NotFoundContainer>
  );
}

export default NotFound;
