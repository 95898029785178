import React from "react";
import { styled } from '@mui/material/styles';
import {CircularProgress, Typography} from "@mui/material";

const PREFIX = 'Loading';

const classes = {
  fullWidth: `${PREFIX}-fullWidth`,
  loader: `${PREFIX}-loader`,
  messageStyle: `${PREFIX}-messageStyle`
};

const Root = styled('div')({
  [`&.${classes.fullWidth}`]: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  },
  [`& .${classes.loader}`]: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexDirection: "column",
  },
  [`& .${classes.messageStyle}`]: {
   marginTop: '50px',
   fontSize: '24px',
  },
});

export default function Loading(props) {
  return (
    <Root className={classes.fullWidth}>
      <div className={classes.loader}>
        <CircularProgress color="primary"/>
        <Typography variant='body1' className={classes.messageStyle}>
          {props.message}
        </Typography>
      </div>
    </Root>
  );
}
