import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import { useDispatch, useSelector } from "react-redux";
import { saveCgu, saveNewsletter, saveSpreadData } from "../../../reducers/campaigns/campaignsSlice";
import ConditionsDisplayer from "../../conditionsDisplayer/ConditionsDisplayer";
import ResponsiveDialogManager from "../../responsiveDialogManager/ResponsiveDialogManager";

const PREFIX = 'Conditions';

const classes = {
  conditions: `${PREFIX}-conditions`,
  conditionContainer: `${PREFIX}-conditionContainer`,
  conditionsCheckbox: `${PREFIX}-conditionsCheckbox`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.conditions}`]: {
    margin: '1rem 1rem 1rem 0',
    display: 'flex',
  },

  [`& .${classes.conditionContainer}`]: {
    textAlign: 'left',
    textAlignLast: 'left',
    marginLeft: '1rem'
  },

  [`& .${classes.conditionsCheckbox}`]: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'center',
      '& > a': {
        marginLeft: '3px',
      }
    },
    display: 'block',
    fontSize: '1rem',
    color: 'rgba(255, 255, 255, 0.7)',
  }
}));

export default function Conditions(props) {

  const { t } = useTranslation();
  const [isModalCguActive, setModalCguActive] = useState(false);
  const [newsletter, setNewsletter] = useState(false);
  const [spreadData, setSpreadData] = useState(false);
  const [cgu, setCgu] = useState(false);
  const dispatch = useDispatch();

  const savedCgu = useSelector((state) => state.campaign.userData.cgu);
  const savedNewsletter = useSelector((state) => state.campaign.userData.newsletter);
  const savedSpreadData = useSelector((state) => state.campaign.userData.spreadData);

  useEffect(() => {
    setCgu(savedCgu || false);
    setNewsletter(savedNewsletter || false);
    setSpreadData(savedSpreadData || false);
  }, [savedCgu, savedNewsletter, savedSpreadData])

  function handleCloseModal() {
    setModalCguActive(false);
  }

  const openCgu = () => {
    setModalCguActive(true);
  }

  function handleCgu() {
    setCgu((prevCgu) => {
      dispatch(saveCgu(!prevCgu));
      props.activateCgu(!prevCgu);
      return !prevCgu;
    });
  }

  function handleNewsletter() {
    setNewsletter((prevNewsletter) => {
      dispatch(saveNewsletter(!prevNewsletter));
      return !prevNewsletter;
    });
  }

  function handleSpreadData() {
    setSpreadData((prevSpreadData) => {
      dispatch(saveSpreadData(!prevSpreadData));
      return !prevSpreadData;
    });
  }

  return (
    <Root className={classes.conditionContainer}>
      <div className={classes.conditions}>
        <Checkbox
          checked={cgu}
          onChange={handleCgu}
          name="cgu"
          color="primary"
        />
        <div className={classes.conditionsCheckbox}>
          <span>
            {t("landing.form.usingTerms")}
          </span>
          <a href="#" onClick={openCgu}>
            {t("landing.form.usingTermsLink")}
          </a>
        </div>
      </div>
      <div className={classes.conditions}>
        <Checkbox
          checked={newsletter}
          onChange={handleNewsletter}
          name="newsletter"
          color="primary"
        />
        <Typography variant="body1" className={classes.conditionsCheckbox}>
          {t("landing.form.subscribeNewsletter")}
        </Typography>
      </div>
      <div className={classes.conditions}>
        <Checkbox
          checked={spreadData}
          onChange={handleSpreadData}
          name="newsletter"
          color="primary"
        />
        <Typography variant="body1" className={classes.conditionsCheckbox}>
          {t("landing.form.videoSeenStart") + props.conf.targetLabel + t("landing.form.videoSeenEnd")}
        </Typography>
      </div>
      <ResponsiveDialogManager
        open={isModalCguActive}
        onClose={handleCloseModal}
        onBackdropClick={handleCloseModal}
        title={t('account.termsOfUse')}
        renderer={<ConditionsDisplayer conditionName={'cgu'} />}
        disableGutters
      />
    </Root>
  );
}
