import React from 'react';
import { styled } from '@mui/material/styles';
import { Divider } from '@mui/material';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import { useTranslation } from 'react-i18next';
import SettingsIcon from '@mui/icons-material/Settings';
import { useViewport } from '../../helpers';

const PREFIX = 'BackdropCardInfo';

const classes = {
  alignItems: `${PREFIX}-alignItems`,
  root: `${PREFIX}-root`,
  leftContainer: `${PREFIX}-leftContainer`,
  rightContainer: `${PREFIX}-rightContainer`,
  logoStyle: `${PREFIX}-logoStyle`,
  divider: `${PREFIX}-divider`,
  title: `${PREFIX}-title`,
  joinExparienceText: `${PREFIX}-joinExparienceText`,
  descriptionApp: `${PREFIX}-descriptionApp`,
  bottomIconsContainer: `${PREFIX}-bottomIconsContainer`,
  playIcon: `${PREFIX}-playIcon`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.alignItems}`]: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },

  [`& .${classes.root}`]: {
    display: 'flex',
    width: '100%'
  },

  [`& .${classes.leftContainer}`]: {
    padding: '16px 0 16px 16px',
    width: '45%',
    maxWidth: '200px',
    marginRight: '1rem',
    display: 'flex',
    justifyContent: 'center'
  },

  [`& .${classes.rightContainer}`]: {
    padding: '16px 16px 0 0',
    width: 'calc(68% - 2rem)',
    display: 'flex',
    flexDirection: 'column',
  },

  [`& .${classes.logoStyle}`]: {
    width: '100%',
    objectFit: 'contain',
    borderRadius: '10px',
  },

  [`& .${classes.divider}`]: {
    height: '2px',
    width: '100%',
    marginTop: '10px'
  },

  [`& .${classes.title}`]: {
    fontSize: '16px',
    marginBottom: '1rem',
    color: '#a0a0a0',
    fontWeight: '600'
  },

  [`& .${classes.joinExparienceText}`]: {
    marginBottom: '1rem'
  },

  [`& .${classes.descriptionApp}`]: {
    paddingLeft: '10px',
  },

  [`& .${classes.bottomIconsContainer}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '5px'
  },

  [`& .${classes.playIcon}`]: {
    width: '50px',
    height: '50px',
    color: theme.palette.primary.main,
    cusor: 'pointer',
  }
}));

const BackdropCardInfo = (props) => {
  const { t } = useTranslation();
  const { width } = useViewport();

  if (props.element) {
    return (
      <Root>
        <div className={classes.alignItems}>
          <div className={classes.root}>
            <div className={classes.leftContainer}>
              <img
                className={classes.logoStyle}
                src={props.element.logoUrl || props.element.logo}
                alt={`logo url ${props.element.displayName || props.element.name}`}
              />
            </div>
            <div className={classes.rightContainer}>
              <div className={classes.title}>
                {props.element.displayName || props.element.name}
              </div>
              <div className={classes.joinExparienceText}>
                {t('home.joinExparienceText')}{props.element.displayName || props.element.name}!
              </div>
              <div className={classes.descriptionApp}>
                {props.element.descriptionApp ?
                  props.element.descriptionApp
                  :
                  t('home.defaultDescription')
                }
              </div>
            </div>
          </div>
        </div>
        <Divider light className={classes.divider} />
        <div className={classes.bottomIconsContainer}>
          {props.element.shortLink &&
            <SettingsIcon
              className={classes.playIcon}
              onClick={() => window.location.assign(props.element.shortLink)}
            />
          }
          {width <= 768 &&
            <PlayCircleFilledIcon
              className={classes.playIcon}
              onClick={() => window.location.assign(props.element.arLink || 'https://app.fanartzone.com')}
            />
          }
        </div>
      </Root>
    );
  } else {
    return null;
  }
}

export default BackdropCardInfo;