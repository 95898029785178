import React, { useCallback, useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import connectBackground from '../../assets/shattered-island.gif'
import i18next from "i18next";
import {
  Button,
  Typography,
  CircularProgress,
  Container,
  Dialog,
  DialogTitle,
  Card,
  CardContent,
  Grid,
  TextField,
  Divider,
  FormControl,
  FormControlLabel,
  Checkbox,
  Avatar as AvatarUi,
  IconButton,
  DialogContent,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Create, PhotoCamera, Clear, Check } from "@mui/icons-material";
import ResponsiveDialogManager from "../../components/responsiveDialogManager/ResponsiveDialogManager";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
// Increase pixel density for crop preview quality on retina screens.
import { useSnackbar } from "notistack";
import { Controller, useForm } from "react-hook-form";
import AsyncCreatableSelect from 'react-select/async-creatable';
import './Account.css';
import { useTranslation } from "react-i18next";
import { createLegalAgeDate, useDebounce, useViewport } from '../../helpers';
import UpdatePasswordForm from './updatePassword';
import { BrowserView, MobileView } from "react-device-detect";
import UpdateEmailForm from '../../components/forms/UpdateEmailForm';
import { checkEmailExist } from '../../services/user.alreadyExist';
import { userServices } from '../../services';
import { useLocation } from "react-router-dom";
import PhoneInput from 'react-phone-input-2'
import AvatarCreator from '../../components/avatarCreator/AvatarCreator';
import 'react-phone-input-2/lib/style.css'
import ViewInAR from '../../assets/viewInAR.svg';
import FanArtZone from '../../assets/faz.png';
import clsx from 'clsx';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import 'dayjs/locale/fr';
import { getAccountInfo, updateAccountInfo, updateAvatar } from '../../reducers/user/userThunks';
import ConditionsDisplayer from '../../components/conditionsDisplayer/ConditionsDisplayer';

const PREFIX = 'Account';

const classes = {
  root: `${PREFIX}-root`,
  backdrop: `${PREFIX}-backdrop`,
  rootAppNative: `${PREFIX}-rootAppNative`,
  containerProfilePicture: `${PREFIX}-containerProfilePicture`,
  myAvatarWebXr: `${PREFIX}-myAvatarWebXr`,
  avatarShape: `${PREFIX}-avatarShape`,
  displayNone: `${PREFIX}-displayNone`,
  formDisplayInfo: `${PREFIX}-formDisplayInfo`,
  profilePicture: `${PREFIX}-profilePicture`,
  avatarDiv: `${PREFIX}-avatarDiv`,
  withAvatar: `${PREFIX}-withAvatar`,
  withoutAvatar: `${PREFIX}-withoutAvatar`,
  editProfilePicture: `${PREFIX}-editProfilePicture`,
  editProfileAvatar: `${PREFIX}-editProfileAvatar`,
  editProfilePicturePencil: `${PREFIX}-editProfilePicturePencil`,
  editProfilePictureInDialog: `${PREFIX}-editProfilePictureInDialog`,
  loader: `${PREFIX}-loader`,
  alignCenter: `${PREFIX}-alignCenter`,
  creatableSelect: `${PREFIX}-creatableSelect`,
  marginTop1: `${PREFIX}-marginTop1`,
  accountMainContainer: `${PREFIX}-accountMainContainer`,
  resetPasswordButton: `${PREFIX}-resetPasswordButton`,
  changeEmailButton: `${PREFIX}-changeEmailButton`,
  mobileViewContainer: `${PREFIX}-mobileViewContainer`,
  changeEmailButtonResponsive: `${PREFIX}-changeEmailButtonResponsive`,
  fullWidth: `${PREFIX}-fullWidth`,
  linkColor: `${PREFIX}-linkColor`,
  phoneInput: `${PREFIX}-phoneInput`,
  dialogButtonsContainer: `${PREFIX}-dialogButtonsContainer`,
  dialogButton: `${PREFIX}-dialogButton`,
  dialogTitle: `${PREFIX}-dialogTitle`,
  loaderContainer: `${PREFIX}-loaderContainer`,
  loadingIcon: `${PREFIX}-loadingIcon`,
  webVrPoster: `${PREFIX}-webVrPoster`,
  webVrButton: `${PREFIX}-webVrButton`,
  activateARText: `${PREFIX}-activateARText`,
  formStyle: `${PREFIX}-formStyle`,
  agePickerContainer: `${PREFIX}-agePickerContainer`,
  removePaddingTop: `${PREFIX}-removePaddingTop`,
  phoneInputStyle: `${PREFIX}-phoneInputStyle`,
  avatarCreatorStyle: `${PREFIX}-avatarCreatorStyle`
};

const AvatarDialogContent = styled(DialogContent)((
  {
    theme
  }
) => ({
  [`& .${classes.dialogButtonsContainer}`]: {
    marginTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  [`& .${classes.displayNone}`]: {
    display: "none",
  },
  [`& .${classes.dialogButton}`]: {
    marginBottom: '1rem'
  },
  [`& .${classes.avatarCreatorStyle}`]: {
    width: '100%',
    height: '100%',
  },
}));

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.displayNone}`]: {
    display: "none",
  },
  [`& .${classes.root}`]: {
    backgroundImage: `url(${connectBackground})`,
    height: '20vh',
    padding: '2rem 2rem 2rem 16px',
  },

  [`& .${classes.backdrop}`]: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },

  [`& .${classes.rootAppNative}`]: {
    backgroundImage: `url(${connectBackground})`,
    height: '25vh',
    padding: '4rem 2rem 2rem 16px',
  },

  [`& .${classes.containerProfilePicture}`]: {
    textAlign: 'center',
    maxWidth: '150px',
    maxHeight: '150px',
  },

  [`& .${classes.myAvatarWebXr}`]: {
    textAlign: 'center',
    maxHeight: '150px',
    maxWidth: '90vw',
    position: 'relative',
  },

  [`& .${classes.avatarShape}`]: {
    height: '120px',
    width: '120px',
    borderRadius: "20px",
    marginTop: '-3rem',
    position: 'relative'
  },



  [`& .${classes.formDisplayInfo}`]: {
    backgroundColor: '#303030',
    maxWidth: '100%',
    marginTop: '-2rem',
    paddingTop: '3rem',
  },

  [`& .${classes.profilePicture}`]: {
    height: '120px',
    width: '120px',
    borderRadius: '1rem',
  },

  [`& .${classes.avatarDiv}`]: {
    height: '120px',
    width: '120px',
    backgroundColor: "grey",
    borderRadius: "1rem"
  },

  [`& .${classes.withAvatar}`]: {
    width: '100%',
    height: '100%'
  },

  [`& .${classes.withoutAvatar}`]: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  [`& .${classes.editProfilePicture}`]: {
    position: 'absolute',
    bottom: '-1.5rem',
    right: '50%',
    transform: 'translateX(45px)',
    backgroundColor: 'transparent',
    fontSize: '0.7rem',
  },

  [`& .${classes.editProfileAvatar}`]: {
    position: 'absolute',
    bottom: '-65px',
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: 'transparent',
    fontSize: '0.7rem',
    zIndex: 999,
  },

  [`& .${classes.editProfilePicturePencil}`]: {
    width: '0.7em',
    height: '0.7em',
  },

  [`& .${classes.editProfilePictureInDialog}`]: {
    position: 'relative',
    width: "calc(100vw - 32px)",
    height: "calc(100vh - 64px)",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '600px',
  },

  [`& .${classes.loader}`]: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '400px',
    marginLeft: '-14px',
  },

  [`& .${classes.alignCenter}`]: {
    textAlign: 'center',
  },

  [`& .${classes.creatableSelect}`]: {
    backgroundColor: 'transparent !important',
  },

  [`& .${classes.marginTop1}`]: {
    marginTop: '2rem',
  },

  [`& .${classes.accountMainContainer}`]: {
    marginBottom: '1rem',
  },

  [`& .${classes.resetPasswordButton}`]: {
    position: 'absolute',
    top: '-25px',
    right: '16px',
    maxWidth: '350px'
  },

  [`& .${classes.changeEmailButton}`]: {
    position: 'absolute',
    top: '-25px',
    left: '16px',
    maxWidth: '350px',
  },

  [`& .${classes.mobileViewContainer}`]: {
    width: '100%',
  },

  [`& .${classes.changeEmailButtonResponsive}`]: {
    marginBottom: '1rem',
  },

  [`& .${classes.fullWidth}`]: {
    width: '100%',
  },

  [`& .${classes.linkColor}`]: {
    color: theme.palette.primary.main,
  },

  [`& .${classes.phoneInput}`]: {
    border: '1px solid rgba(211,175,95,0.3)',
    borderRadius: '5px',
    '& > .MuiInput-underline:before': {
      borderBottom: 'none'
    },
    '& > .MuiInput-underline:hover': {
      borderBottom: 'none'
    },
    '& > .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: '1px solid white',
    },
  },



  [`& .${classes.dialogTitle}`]: {
    textAlign: 'center',
    position: 'relative',
  },

  [`& .${classes.loaderContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#484848',
    padding: '1rem',
    borderRadius: '12px',
    minWidth: '300px',
    textAlign: 'center',
  },

  [`& .${classes.loadingIcon}`]: {
    margin: '1rem auto',
  },

  [`& .${classes.webVrPoster}`]: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundImage: `url(${FanArtZone})`,
    backgroundSize: '250px 250px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },

  [`& .${classes.webVrButton}`]: {
    backgroundColor: theme.palette.primary.main,
    color: '#434343',
    borderRadius: '4px',
    border: 'none',
    position: 'absolute',
    top: '1.5rem',
    right: '16px',
    zIndex: '999',
    display: 'flex',
  },

  [`& .${classes.activateARText}`]: {
    transform: 'translateY(5px)',
    marginLeft: '0.5rem'
  },

  [`& .${classes.formStyle}`]: {
    width: '100%'
  },

  [`& .${classes.agePickerContainer}`]: {
    display: 'flex',
    justifyContent: 'left',
    marginTop: '1rem',
    marginLeft: '16px',
    width: '100%',
    '& > div ': {
      marginTop: 0,
      marginBottom: '16px',
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      marginleft: '0',
    }
  },

  [`& .${classes.removePaddingTop}`]: {
    paddingTop: '0 !important'
  },

  [`& .${classes.phoneInputStyle}`]: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: '1rem'
    }
  },

}));

const pixelRatio = window.devicePixelRatio || 1;

export default function Account() {

  const { t } = useTranslation();
  const { width } = useViewport();
  const userDetails = useSelector((state) => state.user.currentUser.accountDetails);
  const isLoading = useSelector((state) => state.user.currentUser.accountDetails.loading);
  const accountEmail = useSelector(state => state.user.currentUser.account?.email);
  const accountDetailsEmail = useSelector(state => state.user.currentUser.accountDetails.account?.email);
  const updatingAvatar = useSelector(state => state.user.updatingAvatar);
  const [isUpdatePasswordOpen, setIsUpdatePasswordOpen] = useState(false);
  const [isUpdateEmailOpen, setIsUpdateEmailOpen] = useState(false);
  const [birthdate, setBirthdate] = useState(null);
  const [myAvatar, setMyAvatar] = useState();
  const [newPic, setNewPic] = useState(null);
  const [open, setOpen] = useState(false);
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ unit: "%", width: 30, aspect: 1 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { register, handleSubmit, control, errors, watch, getValues } = useForm();
  const [clubInput, setClubInput] = useState("");
  const [sportInput, setSportInput] = useState("");
  const [starsInput, setStarsInput] = useState("");
  const [loadingClubs, setLoadingClubs] = useState(false);
  const [loadingSports, setLoadingSports] = useState(false);
  const [loadingStars, setLoadingStars] = useState(false);
  // manage in the state your current debounce (function, delay).
  const [debounce, setDebounce] = useState({});
  const [previewDocument, setPreviewDocument] = useState(false);
  const [isEmailUsed, setIsEmailUsed] = useState(null);
  const [isEmailSearching, setIsEmailSearching] = useState(false);
  const debouncedSearchEmail = useDebounce(watch('email'), 300);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [cellphoneNumber, setCellphoneNumber] = useState(null);
  const [newsletter, setNewsletter] = useState(false);
  const [modifyAvatar, setModifyAvatar] = useState(false);
  const [avatarCreator, setAvatarCreator] = useState(false);
  const location = useLocation();

  const fetchAccountInfos = async () => {
    try {
      const thunk = await dispatch(getAccountInfo());
      if (thunk.meta.requestStatus === 'rejected')
        throw new Error('offline')
    } catch {
      enqueueSnackbar(t('toasts.unknownError'), { variant: 'error', });
    }
  }

  useEffect(() => {
    fetchAccountInfos();
  }, []);

  useEffect(() => {
    setBirthdate(userDetails.birthDate);
  }, [userDetails.birthDate])

  function handleAssociateAccount() {
    setIsEmailSent(true);
    userServices.sendEmailForAssociation(
      setIsEmailSent,
      getValues('email'),
      () => enqueueSnackbar(t('toasts.emailSent'), { variant: 'success', }),
      () => enqueueSnackbar(t('toasts.unknownError'), { variant: 'error', })
    )
  }

  const onSubmit = async (data) => {
    data.phone = cellphoneNumber;
    data.newsletter = newsletter;
    data.birthdate = dayjs(birthdate).format('YYYY-MM-DD');
    // const successToast = () => enqueueSnackbar(t('toasts.userUpdateSuccess'), { variant: 'success', });
    // const errorToast = () => enqueueSnackbar(t('toasts.userUpdateError'), { variant: 'error', });

    // dispatch(userActions.updateAccountInfo(data, successToast, errorToast))
    try {
      const thunk = await dispatch(updateAccountInfo(data));
      if (thunk.meta.requestStatus === 'rejected')
        throw new Error('offline')
      enqueueSnackbar(t('toasts.userUpdateSuccess'), { variant: 'success', })
    } catch {
      enqueueSnackbar(t('toasts.userUpdateError'), { variant: 'error', });
    }
  }

  useEffect(
    () => {
      if (debouncedSearchEmail && /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(watch('email'))) {
        setIsEmailSearching(true);
        checkEmailExist(debouncedSearchEmail).then(results => {
          setIsEmailSearching(false);
          // Filter out results
          setIsEmailUsed(results);
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearchEmail]
  )

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

  }, [completedCrop]);

  useEffect(() => {
    if (userDetails.avatar?.includes('RPM')) {
      const tmp = userDetails.avatar.split('/');
      const userId = tmp[tmp.length - 1].split('.')[0].split('-')[1];
      setMyAvatar(`https://models.readyplayer.me/${userId}.glb`)
    } else {
      setMyAvatar(userDetails.avatar);
    }
    if (userDetails?.newsletter) {
      setNewsletter(userDetails.newsletter);
    }
    if (userDetails && userDetails.phone && userDetails.phone.length > 0) {
      setCellphoneNumber(userDetails.phone);
    }
  }, [userDetails]);

  function handleChange(file) {
    if (modifyAvatar)
      setModifyAvatar(false)
    const reader = new FileReader();
    reader.onloadend = (value) => {
      setNewPic(value.target.result);
    };
    toggleDialog();
    reader.readAsDataURL(file.target.files[0]);
    file.target.value = "";
  }

  function toggleDialog() {
    setOpen((prevState) => !prevState);
  }

  const saveAvatar = async () => {
    try {
      const thunk = await dispatch(updateAvatar(previewCanvasRef.current.toDataURL()));
      if (thunk.meta.requestStatus === 'rejected')
        throw new Error('offline')
      enqueueSnackbar(t('toasts.avatarUpdateSuccess'), { variant: 'success', });
      setMyAvatar(previewCanvasRef.current.toDataURL());
      toggleDialog();
    } catch {
      enqueueSnackbar(t('toasts.avatarUpdateError'), { variant: 'error', });
    }
  }

  // Listen to changes of debounce (function, delay), when it does clear the previos timeout and set the new one.
  useEffect(() => {
    const { cb, delay } = debounce;
    if (cb) {
      const timeout = setTimeout(cb, delay);
      return () => clearTimeout(timeout);
    }
  }, [debounce]);

  function swapLoaderValue(key, value) {
    if (key === 'clubs') {
      setLoadingClubs(value)
    } else if (key === "sports") {
      setLoadingSports(value)
    } else {
      setLoadingStars(value);
    }
  }

  const promiseOptions = (value, callback, key) => {
    swapLoaderValue(key, true);
    setDebounce({
      cb: async () => {
        const data = await userServices.fetchAssociateTags(key, value, () => swapLoaderValue(key, false));
        swapLoaderValue(key, false);
        data.forEach(item => {
          item.label = item.name;
          item.value = item.name;
        })
        callback(data)
      },
      delay: 500 //ms
    })
  }

  const setAvatarInformations = async (data) => {
    let avatarUrl = "";
    if (typeof data === 'string' || data instanceof String)
      avatarUrl = data;
    else
      avatarUrl = data.url;
    const splittedString = avatarUrl.split('/');
    const userId = splittedString[splittedString.length - 1].split('.')[0];
    try {
      const thunk = await dispatch(updateAvatar(`https://api.readyplayer.me/v1/avatars/${userId}.png`));
      if (thunk.meta.requestStatus === 'rejected')
        throw new Error('offline')
      enqueueSnackbar(t('toasts.avatarUpdateSuccess'), { variant: 'success', });
      setMyAvatar(`https://api.readyplayer.me/v1/avatars/${userId}.png`)
      setModifyAvatar(false);
      setAvatarCreator(false);
    } catch {
      enqueueSnackbar(t('toasts.avatarUpdateError'), { variant: 'error', });
    }
  }

  if (!userDetails) {
    return (
      <div className="loading-container">
        <CircularProgress size={50} />
        <Typography>{t('home.fetching')}</Typography>
      </div>
    );
  } else {
    return (
      <Root>
        <Container className={location.pathname.indexOf("mobile") > 0 ? classes.rootAppNative : classes.root}>
          <Typography variant='h3'>
            {userDetails.firstname ? `${userDetails.firstname} ${userDetails.lastname}` : t('account.myProfile')}
          </Typography>
        </Container>
        <Container className={myAvatar?.includes('.glb') ? classes.myAvatarWebXr : classes.containerProfilePicture}>
          <Dialog
            fullScreen={avatarCreator}
            onClose={() => setModifyAvatar(false)}
            aria-labelledby="simple-dialog-title"
            open={modifyAvatar}
            maxWidth={false}
          >
            <DialogTitle
              id="simple-dialog-title"
              className={classes.dialogTitle}
            >
              {t('account.changeProfileImage')}
              {avatarCreator &&
                <IconButton
                  color="primary"
                  aria-label="delete element"
                  component="span"
                  onClick={() => setAvatarCreator(false)}
                  disabled={updatingAvatar}
                  style={{
                    position: 'absolute',
                    right: '1rem',
                    top: '8px',
                  }}
                >
                  <Clear />
                </IconButton>
              }
            </DialogTitle>
            <AvatarDialogContent>
              {!avatarCreator ?
                <div className={classes.dialogButtonsContainer}>
                  <input
                    onChange={handleChange}
                    accept="image/*"
                    id="icon-button-file-in-dialog"
                    className={classes.displayNone}
                    type="file"
                  />
                  <label htmlFor="icon-button-file-in-dialog">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      className={classes.dialogButton}
                    >
                      {t('account.selectFile')}
                    </Button>
                  </label>
                  <Typography variant="body1" className={classes.dialogButton}>
                    {t('account.Or')}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setAvatarCreator(true)}
                  >
                    {t('account.createMyAvatar')}
                  </Button>
                </div>
                :
                <div className={classes.avatarCreatorStyle}>
                  <AvatarCreator
                    setAvatarInformations={setAvatarInformations}
                  />
                </div>
              }
            </AvatarDialogContent>
          </Dialog>
          {myAvatar?.includes('.glb') ?
            <>
              <model-viewer
                src={myAvatar}
                alt="ready player me avatar"
                ar
                // reveal="interaction" ///  private / undocumented Scene Viewer API and is subject to change at any time! -- display VR only after user interaction
                // ar-placement="wall"
                // ar-modes="webxr scene-viewer quick-look"
                shadow-intensity="1"
                ar-modes="webxr scene-viewer quick-look"
                camera-controls //enable user interaction
                ar-placement="wall"
                id="model-viewer"
                min-field-of-view="90deg"
                max-field-of-view="100deg"
                camera-orbit="0deg 90deg 0.5m" // initial angle and position of camera
                // disable-zoom
                style={{
                  height: '400px',
                  position: 'absolute',
                  top: '-260px',
                  width: '100%',
                  left: '50%',
                  transform: 'translateX(-50%)',
                }}
              >
                <div id="lazy-load-poster" slot="poster" className={classes.webVrPoster}></div>
                <button
                  slot="ar-button"
                  className={classes.webVrButton}
                >
                  <img
                    id="ViewInAR"
                    alt="ViewInAR-icon"
                    src={ViewInAR}
                  />
                  <div className={classes.activateARText}>
                    {t('myDesign.webVR.activateAR')}
                  </div>
                </button>
                <div id="error" className="hide">{t('myDesign.webVR.ARNotSupported')}</div>
              </model-viewer>
              <div>
                <input
                  onClick={() => setModifyAvatar(true)}
                  // accept="image/*"
                  id="icon-button-file"
                  className={classes.displayNone}
                // type="file"
                />
                <div className={classes.withAvatar}>
                  <label htmlFor="icon-button-file">
                    <Button className={classes.editProfileAvatar} color="primary" aria-label="upload picture"
                      component="span" size="small">
                      <span>{t('account.modify')}</span>
                      <Create size="small" className={classes.editProfilePicturePencil} />
                    </Button>
                  </label>
                </div>
              </div>
            </>
            :
            <div className={classes.avatarShape}>
              <input
                onClick={() => setModifyAvatar(true)}
                // accept="image/*"
                id="icon-button-file"
                className={classes.displayNone}
              // type="file"
              />
              <div className={classes.avatarDiv}>
                {myAvatar ?
                  <div className={classes.withAvatar}>
                    <AvatarUi variant={'square'} src={myAvatar} className={classes.profilePicture} alt="my Avatar" />
                    <label htmlFor="icon-button-file">
                      <Button className={classes.editProfilePicture} color="primary" aria-label="upload picture"
                        component="span" size="small">
                        <span>{t('account.modify')}</span>
                        <Create size="small" className={classes.editProfilePicturePencil} />
                      </Button>
                    </label>
                  </div>
                  :
                  <div className={classes.withoutAvatar}>
                    <input
                      onClick={() => setModifyAvatar(true)}
                      // accept="image/*"
                      className={classes.displayNone}
                      id="icon-button-file"
                    // type="file"
                    />
                    <label htmlFor="icon-button-file">
                      <IconButton color="primary" aria-label="upload picture" component="span">
                        <PhotoCamera />
                      </IconButton>
                    </label>
                  </div>
                }
              </div>
            </div>
          }
          <ResponsiveDialogManager
            title={"Avatar"}
            mobile={location.pathname.indexOf("mobile") > 0}
            open={open}
            onClose={toggleDialog}
            renderer={
              <div className={classes.editProfilePictureInDialog}>
                <ReactCrop
                  src={newPic}
                  onImageLoaded={onLoad}
                  crop={crop}
                  onChange={(c) => setCrop(c)}
                  onComplete={(c) => setCompletedCrop(c)}
                />
              </div>
            }
            toolbarActions={
              <Container>
                {updatingAvatar ?
                  <CircularProgress size={28} sx={{ marginRight: '1rem', position: 'absolute', right: '10px', top: '10px' }} />
                  :
                  <Button
                    variant="contained"
                    onClick={saveAvatar}
                    sx={{ marginRight: '1rem', position: 'absolute', right: 0, top: '7px' }}
                  >
                    {t('account.validate')}
                  </Button>
                }
              </Container>
            }
          />
        </Container>
        <Container disableGutters={width > 768} className={classes.accountMainContainer}>
          <Card className={classes.formDisplayInfo}>
            <ResponsiveDialogManager
              mobile={location.pathname.indexOf("mobile") > 0}
              title={t('account.updatePasswordForm.title')}
              open={isUpdatePasswordOpen}
              onClose={() => setIsUpdatePasswordOpen(!isUpdatePasswordOpen)}
              renderer={<UpdatePasswordForm />}
            />
            <ResponsiveDialogManager
              mobile={location.pathname.indexOf("mobile") > 0}
              title={t("account.setNewEmail")}
              open={isUpdateEmailOpen}
              onClose={() => {
                setIsUpdateEmailOpen(!isUpdateEmailOpen)
              }}
              renderer={<UpdateEmailForm />}
            />
            <ResponsiveDialogManager
              mobile={location.pathname.indexOf("mobile") > 0}
              title={!previewDocument ? '' : previewDocument.includes('pc') ? t('account.dataProtection') : t('account.termsOfUse')}
              open={!previewDocument ? false : true}
              fullScreen
              onClose={() => setPreviewDocument(null)}
              renderer={<ConditionsDisplayer conditionName={previewDocument} />}
            />
            <CardContent style={{ position: 'relative' }}>
              <BrowserView>
                {(accountEmail || accountDetailsEmail) &&
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.changeEmailButton}
                    onClick={() => setIsUpdateEmailOpen(true)}
                  >
                    {t('account.changeEmail')}
                  </Button>
                }
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.resetPasswordButton}
                  onClick={() => setIsUpdatePasswordOpen(true)}
                >
                  {t('account.resetPassword')}
                </Button>
              </BrowserView>
              <form onSubmit={handleSubmit(onSubmit)} className={clsx(classes.marginTop1, classes.formStyle)}>
                <Grid spacing={2} container justifyContent={"space-between"}>
                  <Grid item xs={12}>
                    <TextField
                      name="login"
                      disabled
                      label={t('account.form.username')}
                      defaultValue={userDetails.account.login}
                      fullWidth
                      variant="filled"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {accountEmail || accountDetailsEmail ?
                      <TextField
                        inputRef={register}
                        disabled
                        name="email"
                        label={t('account.form.email')}
                        defaultValue={userDetails.account.email}
                        fullWidth
                        variant="filled"
                      />
                      :
                      <FormControl variant="outlined" className={classes.fullWidth}>
                        <InputLabel htmlFor="email">{t('account.form.email')}</InputLabel>
                        <OutlinedInput
                          required
                          fullWidth
                          name="email"
                          id="email"
                          type="text"
                          disabled={isLoading}
                          label={t('account.form.email')}
                          error={errors.hasOwnProperty('email') || isEmailUsed || (!accountEmail && !accountDetailsEmail && !getValues('email'))}
                          autoComplete="email"
                          inputRef={register({
                            required: true,
                            validate: value => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value) && !isEmailUsed
                              ? null : t('account.updateEmailForm.wrongEmailFormat')
                          })}
                          endAdornment={(isEmailUsed !== null || isEmailSearching) &&
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="email availability"
                              >
                                {isEmailSearching ? <CircularProgress /> : isEmailUsed ? <Clear /> : <Check />}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        {isEmailUsed &&
                          <FormHelperText id="email" error>
                            {t('account.updateEmailForm.emailUsed')}
                          </FormHelperText>
                        }
                      </FormControl>
                    }
                  </Grid>
                  <Grid item xs={12}>
                    {(!accountEmail && !accountDetailsEmail) && isEmailUsed &&
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={() => !isEmailSent ?
                          handleAssociateAccount()
                          : undefined}
                      >
                        {t('account.associateEmail')}
                      </Button>
                    }
                  </Grid>
                  <Grid item xs={12} style={{ paddingTop: 0, paddingBottom: '0.5rem' }}>
                    <MobileView>
                      {(accountEmail || accountDetailsEmail) &&
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          className={classes.changeEmailButtonResponsive}
                          onClick={() => setIsUpdateEmailOpen(true)}
                        >
                          {t('account.changeEmail')}
                        </Button>
                      }
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.resetPasswordButtonResponsive}
                        onClick={() => setIsUpdatePasswordOpen(true)}
                      >
                        {t('account.resetPassword')}
                      </Button>
                    </MobileView>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      inputRef={register}
                      name="firstname"
                      disabled={isLoading}
                      label={t('account.form.firstname')}
                      defaultValue={userDetails.firstname}
                      variant={"outlined"} />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      inputRef={register}
                      name="lastname"
                      disabled={isLoading}
                      label={t('account.form.lastname')}
                      defaultValue={userDetails.lastname}
                      variant={"outlined"} />
                  </Grid>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18next.language.substring(0, 2)}>
                    <div className={classes.agePickerContainer}>
                      <DatePicker
                        disableFuture
                        openTo="year"
                        views={['year', 'month', 'day']}
                        label={t('authForms.signin.birthdate')}
                        maxDate={createLegalAgeDate(0, 0, -15)}
                        value={dayjs(birthdate)}
                        onChange={(newValue) => setBirthdate(newValue)}
                        renderInput={(params) => <TextField {...params} placeholder="birthdate" />}
                      />
                    </div>
                  </LocalizationProvider>
                  <Grid item xs={6} className={classes.removePaddingTop}>
                    <TextField
                      inputRef={register}
                      name="city"
                      disabled={isLoading}
                      label={t('account.form.city')}
                      defaultValue={userDetails.city}
                      variant={"outlined"} />
                  </Grid>
                  <Grid item xs={6} className={classes.removePaddingTop}>
                    <TextField
                      inputRef={register}
                      name="country"
                      disabled={isLoading}
                      label={t('account.form.country')}
                      defaultValue={userDetails.country}
                      variant={"outlined"} />
                  </Grid>
                  <Grid item xs={12}>
                    <PhoneInput
                      className={classes.phoneInputStyle}
                      inputStyle={{
                        backgroundColor: '#434343',
                        color: 'white',
                      }}
                      buttonStyle={{
                        backgroundColor: '#434343',
                      }}
                      dropdownStyle={{
                        backgroundColor: '#434343',
                      }}
                      dropdownClass="phoneInputDopdown"
                      // defaultCountry={'fr'}
                      value={cellphoneNumber}
                      onChange={setCellphoneNumber}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider variant="middle" style={{ margin: 0 }} />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2">
                      {t('account.form.heartClubs')}
                    </Typography>
                    <Controller
                      control={control}
                      name="clubs"
                      defaultValue={userDetails.clubs && userDetails.clubs.map(item => ({
                        ...item,
                        value: item.name,
                        label: item.name
                      }))}
                      render={({ onChange }) => (
                        <AsyncCreatableSelect
                          isClearable
                          defaultValue={userDetails.clubs && userDetails.clubs.map(item => ({
                            ...item,
                            value: item.name,
                            label: item.name
                          }))}
                          isMulti
                          isDisabled={isLoading}
                          cacheOptions
                          loadOptions={(value, callback) => promiseOptions(value, callback, 'clubs')}
                          loadingMessage={() => t('account.form.loading')}
                          formatCreateLabel={inputValue => `${t('account.form.create')} ${inputValue}`}
                          noOptionsMessage={() => t('account.form.noResults')}
                          isLoading={loadingClubs}
                          getOptionLabel={option => option.label ? option.label : option.name}
                          placeholder={t('account.form.typeClub')}
                          onKeyDown={(e) => {
                            if (!clubInput) return;
                            switch (e.key) {
                              case 'Enter':
                                onChange(clubInput);
                                setClubInput("");
                                break;
                              case 'Tab':
                                onChange(clubInput);
                                setClubInput("");
                                break;
                              default:
                                setClubInput("");
                                break;
                            }
                          }}
                          onInputChange={(value) => setClubInput(value)}
                          onChange={(newValue, actionMeta) => {
                            if (actionMeta.action !== "pop-value" && actionMeta.action !== "remove-value" && actionMeta.action !== "clear") {
                              const tmpValue = [...newValue]
                              tmpValue.forEach(item => {
                                item.label = item.value;
                                item.name = item.value;
                              });
                              onChange(tmpValue)
                            } else {
                              onChange(!newValue ? [] : newValue)
                            }
                          }}
                          className='react-select-container'
                          classNamePrefix="react-select"
                        />)
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2">
                      {t('account.form.favoriteSports')}
                    </Typography>
                    <Controller
                      control={control}
                      name="sports"
                      defaultValue={userDetails.sports && userDetails.sports.map(item => ({
                        ...item,
                        value: item.name,
                        label: item.name
                      }))}
                      render={({ onChange }) => (
                        <AsyncCreatableSelect
                          isClearable
                          defaultValue={userDetails.sports && userDetails.sports.map(item => ({
                            ...item,
                            value: item.name,
                            label: item.name
                          }))}
                          isMulti
                          cacheOptions
                          isDisabled={isLoading}
                          loadOptions={(value, callback) => promiseOptions(value, callback, 'sports')}
                          loadingMessage={() => t('account.form.loading')}
                          formatCreateLabel={inputValue => `${t('account.form.create')} ${inputValue}`}
                          noOptionsMessage={() => t('account.form.noResults')}
                          getOptionLabel={option => option.label ? option.label : option.name}
                          isLoading={loadingSports}
                          placeholder={t('account.form.typeSports')}
                          onKeyDown={(e) => {
                            if (!sportInput) return;
                            switch (e.key) {
                              case 'Enter':
                                onChange(sportInput);
                                setSportInput("");
                                break;
                              case 'Tab':
                                onChange(sportInput);
                                setSportInput("");
                                break;
                              default:
                                setSportInput("");
                            }
                          }}
                          onInputChange={(value) => setSportInput(value)}
                          onChange={(newValue, actionMeta) => {
                            if (actionMeta.action !== "pop-value" && actionMeta.action !== "remove-value" && actionMeta.action !== "clear") {
                              const tmpValue = [...newValue]
                              tmpValue.forEach(item => {
                                item.label = item.value;
                                item.name = item.value;
                              });
                              onChange(tmpValue)
                            } else {
                              onChange(!newValue ? [] : newValue)
                            }
                          }}
                          className='react-select-container'
                          classNamePrefix="react-select"
                        />)
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2">
                      {t('account.form.favoriteStars')}
                    </Typography>
                    <Controller
                      control={control}
                      name="stars"
                      defaultValue={userDetails.stars && userDetails.stars.map(item => ({
                        ...item,
                        value: item.name,
                        label: item.name
                      }))}
                      render={({ onChange }) => (
                        <AsyncCreatableSelect
                          isClearable
                          defaultValue={userDetails.stars && userDetails.stars.map(item => ({
                            ...item,
                            value: item.name,
                            label: item.name
                          }))}
                          isMulti
                          cacheOptions
                          isDisabled={isLoading}
                          loadOptions={(value, callback) => promiseOptions(value, callback, 'stars')}
                          loadingMessage={() => t('account.form.loading')}
                          formatCreateLabel={inputValue => `${t('account.form.create')} ${inputValue}`}
                          noOptionsMessage={() => t('account.form.noResults')}
                          isLoading={loadingStars}
                          getOptionLabel={option => option.label ? option.label : option.name}
                          placeholder={t('account.form.typeStars')}
                          onKeyDown={(e) => {
                            if (!starsInput) return;
                            switch (e.key) {
                              case 'Enter':
                                onChange(starsInput);
                                setStarsInput("");
                                break;
                              case 'Tab':
                                onChange(starsInput);
                                setStarsInput("");
                                break;
                              default:
                                setStarsInput("");
                            }
                          }}
                          onInputChange={(value) => setStarsInput(value)}
                          onChange={(newValue, actionMeta) => {
                            if (actionMeta.action !== "pop-value" && actionMeta.action !== "remove-value" && actionMeta.action !== "clear") {
                              const tmpValue = [...newValue]
                              tmpValue.forEach(item => {
                                item.label = item.value;
                                item.name = item.value;
                              });
                              onChange(tmpValue)
                            } else {
                              onChange(!newValue ? [] : newValue)
                            }
                          }}
                          className='react-select-container'
                          classNamePrefix="react-select"
                        />)
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={isLoading}
                          checked={newsletter === 1 ? true : false}
                          onChange={e => setNewsletter(e.target.checked)}
                          color="primary"
                        />
                      }
                      label={t('account.form.newsletter')}
                      labelPlacement="end"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      {t('account.accessTermsOfUse')}
                      <span onClick={() => setPreviewDocument("cgu")} className={classes.linkColor} style={{ cursor: 'pointer' }}>
                        {t('account.termsOfUse')}
                      </span>
                      {t('account.and')}
                      <span onClick={() => setPreviewDocument("pc")} className={classes.linkColor} style={{ cursor: 'pointer' }}>
                        {t('account.dataProtection')}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.alignCenter}>
                    <Button color="primary" variant="contained" type="submit" disabled={isLoading}>
                      {isLoading ? <CircularProgress size={28} /> : t('account.form.confirmButton')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
          <canvas
            ref={previewCanvasRef}
            className={classes.displayNone}
          />
        </Container>
      </Root>
    );
  }
}
