import { createSlice } from "@reduxjs/toolkit";
import secureLocalStorage from "react-secure-storage";
import {
  getAccountInfo,
  login,
  recoverPassword,
  register,
  resetPassword,
  socialLogin,
  updateAccountInfo,
  updateAvatar,
} from "./userThunks";

function initializeUserState() {
  const currentUser = JSON.parse(secureLocalStorage.getItem("user"));

  let initialState = {
    loading: false,
    error: null,
    success: null,
    recoverySuccess: null,
    // user
    loggedIn: false,
    //updateAvatar
    updatingAvatar: false,
    // updateAccountInfos
    currentUser: {
      accountDetails: {
        loading: false,
      },
    },
  };
  if (currentUser) {
    initialState.currentUser = currentUser;
    initialState.loggedIn = true;
  }

  return initialState;
}

export const userSlice = createSlice({
  name: "user",
  initialState: initializeUserState(),
  reducers: {
    resetError: (state) => {
      state.error = null;
    },
    logout: (state) => {
      secureLocalStorage.removeItem("user");
      state.loading = false;
      state.error = null;
      state.success = null;
      state.recoverySuccess = null;
      state.loggedIn = false;
      state.updatingAvatar = false;
      state.currentUser = {
        accountDetails: {
          loading: false,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateAccountInfo.pending, (state) => {
        state.currentUser.accountDetails.loading = true;
      })
      .addCase(updateAccountInfo.fulfilled, (state, action) => {
        state.currentUser.accountDetails.loading = false;
        state.currentUser.accountDetails = {
          ...state.currentUser.accountDetails,
          ...action.payload,
        };
      })
      .addCase(updateAccountInfo.rejected, (state) => {
        state.currentUser.accountDetails.loading = false;
      })
      .addCase(getAccountInfo.pending, (state) => {
        state.currentUser.accountDetails.loading = true;
      })
      .addCase(getAccountInfo.fulfilled, (state, action) => {
        state.currentUser.accountDetails.loading = false;
        state.currentUser.accountDetails = { ...action.payload };
      })
      .addCase(getAccountInfo.rejected, (state) => {
        state.currentUser.accountDetails.loading = false;
      })
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.loggedIn = true;
        state.currentUser = action.payload;
      })
      .addCase(login.rejected, (state) => {
        state.loading = false;
      })
      .addCase(socialLogin.pending, (state) => {
        state.loading = true;
      })
      .addCase(socialLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.loggedIn = true;
        state.currentUser = action.payload;
      })
      .addCase(socialLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(register.pending, (state) => {
        state.loading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(register.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateAvatar.pending, (state) => {
        state.updatingAvatar = true;
      })
      .addCase(updateAvatar.fulfilled, (state, action) => {
        state.updatingAvatar = false;
        if (
          action.payload.data.avatar === state.currentUser.accountDetails.avatar
        )
          state.currentUser.accountDetails.avatar = action.payload.old;
        else
          state.currentUser.accountDetails.avatar = action.payload.data.avatar;
      })
      .addCase(updateAvatar.rejected, (state) => {
        state.updatingAvatar = false;
      })
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload;
      })
      .addCase(resetPassword.rejected, (state) => {
        state.loading = false;
        state.error = "Reset error";
      })
      .addCase(recoverPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(recoverPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload;
      })
      .addCase(recoverPassword.rejected, (state) => {
        state.loading = false;
        state.error = "Recovery error";
      });
  },
});

// Action creators are generated for each case reducer function
export const { resetError, logout } = userSlice.actions;

export default userSlice.reducer;
