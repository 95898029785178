import React, {useEffect, useState} from "react";
import {TextField} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Fab from "@mui/material/Fab";
import CheckIcon from "@mui/icons-material/Check";
import SendIcon from "@mui/icons-material/Send";
import CircularProgress from "@mui/material/CircularProgress";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import './code-vip.css';
import {useSnackbar} from "notistack";
import { checkVipCode } from "../../../reducers/campaigns/campaignsThunks";

export default function CodeVip(props) {
  const {t} = useTranslation();
  const [code, setCode] = useState('');
  const [codeOk, setCodeOk] = useState(false);
  const codeVipSuccess = useSelector((state) => state.campaign.codeVip);
  const codeVipChecking = useSelector((state) => state.campaign.codeVipChecking);
  const errorCode = useSelector((state) => state.campaign.error);
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();

  function handleCode(e) {
    setCode(e.target.value ? e.target.value.toUpperCase() : '');
    checkLengthCode();
  }

  function checkLengthCode() {
    return setCodeOk(code.length > 3);
  }

  const checkCode = async () => {
    try {
      const thunk = await dispatch(checkVipCode({code, uniqueKey: props.conf.uniqueKey}));
      if (thunk.meta.requestStatus === 'rejected')
        throw new Error('offline')
    } catch {}
  }

  useEffect(() => {
    if (codeVipSuccess) {
      props.nextButton(false);
      setTimeout(() => {
        if (codeOk) {
          enqueueSnackbar(t('landing.vip.successCode'), {variant: "success", autoHideDuration: 3000});
        }
      }, 1000);
    } else {
      props.nextButton(true);
    }
    if (errorCode) {
      enqueueSnackbar(t(errorCode), {variant: "error", autoHideDuration: 3000});
    }
  }, [codeVipChecking, codeVipSuccess]);

  return (
    <div className="vip-code">
      <div className="single-input-container landing-vip-single-input-container">
        <TextField
          id="outlined-basic-4"
          variant="outlined"
          value={code}
          onChange={handleCode}
          helperText={t("landing.vip.helper")}
          InputProps={{
            startAdornment: <InputAdornment position="start">VIP-</InputAdornment>,
          }}
        />
        <div className="button-div">
          <Fab
            disabled={codeVipSuccess}
            aria-label="save"
            color="primary"
            className="buttonClassname"
            onClick={checkCode}
          >
            {codeVipSuccess ? <CheckIcon/> : <SendIcon/>}
          </Fab>
          {codeVipChecking && <CircularProgress size={68} className="fabProgress"/>}
        </div>
      </div>
    </div>
  )
}
