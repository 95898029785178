import React, {useState} from 'react';
import { CircularProgress, Skeleton, styled, Typography } from '@mui/material';
import CardsDisplayer from '../cardsDisplayer/CardsDisplayer';
import { useTranslation } from 'react-i18next';

const PREFIX = 'EntityDisplayer';

const classes = {
  root: `${PREFIX}-root`,
  entityLogo: `${PREFIX}-entityLogo`,
  loaderContainer: `${PREFIX}-loaderContainer`
};

const EntityDisplayerContainer = styled('div')(({ theme }) => {
  return ({
    [`& .${classes.root}`]: {
      maxWidth: '650px',
      margin: '0 auto',
      '& > div > div:first-of-type': {
      margin: '0.5rem 0',
      }
    },
    [`& .${classes.entityLogo}`]: {
      width: '100%',
    },
  })
});

const EntityDisplayerLoader = styled('div')(({ theme }) => {
  return ({
    [`& .${classes.loaderContainer}`]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
  })
});

const EntityDisplayer = (props) => {
  const { t } = useTranslation();
  const [isLoaded, setIsLoaded] = useState(false);

  if (!props.data?.expariences) {
    return (
      <EntityDisplayerLoader>
        <div className={classes.loaderContainer}>
          <CircularProgress size={50} />
          <Typography>{t('home.fetching')}</Typography>
        </div>
      </EntityDisplayerLoader>
    )
  } else {
    return (
      <EntityDisplayerContainer>
        <div className={classes.root}>
          <img
            src={props.data.header}
            alt="entity logo"
            className={classes.entityLogo}
            style={{
              display: !isLoaded && 'none'
            }}
            onLoad={() => setIsLoaded(true)}
          />
          {!isLoaded &&
            <Skeleton variant="rectangular" height={350}/>
          }
          <CardsDisplayer
            title={t('home.currentExpariences')}
            elements={props.data.expariences}
            handleBackdrop={props.handleBackdrop}
            id="expariences"
          />
        </div>
      </EntityDisplayerContainer>
    )
  }
}

export default EntityDisplayer;