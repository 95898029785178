import React from "react";
import { styled } from '@mui/material/styles';
import { Button } from "@mui/material";
import { openModal } from "../../../reducers/campaigns/campaignsSlice";
import './informations.css';
import Divider from "@mui/material/Divider";
import { useDispatch, useSelector } from "react-redux";
import UserForm from "../../forms/UserForm";
import { useTranslation } from "react-i18next";
import { logout } from "../../../reducers/user/userSlice";

const PREFIX = 'Informations';

const classes = {
  rootContainer: `${PREFIX}-rootContainer`,
  buttons: `${PREFIX}-buttons`,
  buttonInformation: `${PREFIX}-buttonInformation`,
  section: `${PREFIX}-section`,
  buttonStyle: `${PREFIX}-buttonStyle`,
  divider: `${PREFIX}-divider`,
  contentForm: `${PREFIX}-contentForm`,
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.buttons}`]: {
    margin: "1rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  [`& .${classes.buttonInformation}`]: {
    display: 'flex',
    flexDirection: 'column',
    margin: '1rem',
    textAlign: 'center',
  },
  [`& .${classes.section}`]: {
    marginBottom: '1rem',
  },
  [`& .${classes.buttonStyle}`]: {
    position: 'absolute',
    right: '0',
    bottom: '98%',
    fontSize: '10px',
  },
  [`& .${classes.rootContainer}`]: {
    margin: '1rem',
  },
  [`& .${classes.divider}`]: {
    width: '100%'
  },
  [`& .${classes.contentForm}`]: {
    position: 'relative',
  },
}));

const CustomButton = Button;

export default function Informations(props) {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);
  const isLoggedIn = useSelector((state) => state.user.loggedIn)

  function disableButtons(value) {
    props.disableButtons(value);
  }

  function checkForm(value) {
    disableButtons(!value);
  }

  return (
    <Root>
      <div className={classes.rootContainer}>
        {(!isLoggedIn) ?
          <div className={(classes.buttons)}>
            <div className={(classes.buttonInformation)}>
              <div className={(classes.section)}>{t('account.connexion-description')}</div>
              <CustomButton
                onClick={() => dispatch(openModal('login'))}
                classes={{
                  root: classes.root
                }}
              >
                {t('account.connexion')}
              </CustomButton>
            </div>
            <Divider className={classes.divider} />
            <div className={(classes.buttonInformation)}>
              <div className={(classes.section)}>{t('account.subscription-description')}</div>
              <CustomButton
                onClick={() => dispatch(openModal('register'))}
                classes={{
                  root: classes.root
                }}
              >
                {t('account.subscription')}
              </CustomButton>
            </div>
          </div>
          :
          <div className={classes.contentForm}>
            <UserForm user={user.account} isFormOk={checkForm} nextStep={props.nextStep} />
            <Button
              variant="contained"
              onClick={() => dispatch(logout())}
              className={classes.buttonStyle}
              size="small"
            >
              {t('topMenu.logout')}
            </Button>
          </div>
        }
      </div>
    </Root>
  );
}
