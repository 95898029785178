import {authHeader} from '../helpers';
import secureLocalStorage from 'react-secure-storage';

export const userServices = {
  login,
  socialLogin,
  logout,
  register,
  update,
  recoverPassword,
  resetPassword,
  validateAccount,
  updateAvatar,
  updateAccountInfo,
  getAccountInfo,
  updatePasswordFromAccount,
  sendEmailForUpdate,
  sendEmailForAssociation,
  fetchAssociateTags,
};

function login(username, password) {
  const requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({login: username, password})
  };

  return fetch(`${process.env.REACT_APP_API_URL}/accounts/authenticate`, requestOptions)
    .then(handleResponse)
    .then(user => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      secureLocalStorage.setItem('user', JSON.stringify(user));
      return user;
    });
}

function socialLogin(socialUser) {
  const requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({profile: socialUser})
  };

  return fetch(`${process.env.REACT_APP_API_URL}/accounts/authenticate/social`, requestOptions)
    .then(handleResponse)
    .then(user => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      secureLocalStorage.setItem('user', JSON.stringify(user));
      return user;
    });
}

function recoverPassword(email) {
  const requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({login: email})
  };

  return fetch(`${process.env.REACT_APP_API_URL}/reset-password`, requestOptions)
    .then(res => {
      if (res.status === 200) {
        return 'An email has been sent to your adress'
      } else {
        const error = (res && res.message) || res.statusText;
        return Promise.reject(error);
      }
    });
}

function updatePasswordFromAccount(currentPassword, password) {
  const requestOptions = {
    method: 'PUT',
    mode: "cors",
    headers: {...authHeader(), 'Accept': 'application/json'},
    body: JSON.stringify({
      currentPassword,
      password,
    })
  };

  return fetch(process.env.REACT_APP_API_URL + '/account-password', requestOptions)
    .then(response => {
      if (response.status === 200) {
        return Promise.resolve();
      } else if (response.status === 403) {
        throw new Error('Wrong current password');
      } else {
        throw new Error('Unable to update account password');
      }
    })
}

function resetPassword(key, password) {
  const requestOptions = {
    method: 'PUT',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({key, password})
  };

  return fetch(`${process.env.REACT_APP_API_URL}/account-password`, requestOptions)
    .then(res => {
      if (res.status === 200) {
        return 'Your password has been modified !'
      } else {
        const error = (res && res.message) || res.statusText;
        return Promise.reject(error);
      }
    });
}

function logout() {
  // remove user from local storage to log user out
  secureLocalStorage.removeItem('user');
}

function register(user) {
  const requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(user)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/accounts/register`, requestOptions).then(handleResponse);
}

function update(user) {
  const requestOptions = {
    method: 'PUT',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
    body: JSON.stringify(user)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/users/${user.id}`, requestOptions).then(handleResponse);
}

function validateAccount(key) {
  const requestOptions = {
    method: 'PUT',
  };

  return fetch(`${process.env.REACT_APP_API_URL}/accounts/validate/${key}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        // eslint-disable-next-line no-restricted-globals
        location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

async function updateAvatar(imageSrc) {
  const blob = await fetch(imageSrc).then((res) => res.blob());
  const formData = new FormData();
  formData.append("avatar", blob, "myAvatar.png");

  const myResponse = async (response) => {
    const data = await handleResponse(response);
    const user = JSON.parse(secureLocalStorage.getItem('user'));
    if (user) {
      user.accountDetails.avatar = data.avatar;
      secureLocalStorage.setItem('user', JSON.stringify(user));
    }
    return data;
  };
  const requestOptions = {
    method: 'POST',
    headers: {...authHeader(true)},
    body: formData
  };

  return fetch(`${process.env.REACT_APP_API_URL}/accounts/me/avatar`, requestOptions).then(myResponse);
}

function sendEmailForAssociation(setIsEmailSent, email, successToast, errorToast) {
  const requestOptions = {
    method: 'PUT',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({email})
  };

  return fetch(`${process.env.REACT_APP_API_URL}/accounts/associate`, requestOptions)
    .then(res => {
      if (res.status === 200) {
        successToast();
        return Promise.resolve();
      } else {
        const error = (res && res.message) || res.statusText;
        errorToast();
        setIsEmailSent(false);
        return Promise.reject(error);
      }
    });
}

function sendEmailForUpdate(email, successToast, errorToast) {
  const requestOptions = {
    method: 'PUT',
    headers: {...authHeader()},
    // headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({email})
  };

  return fetch(`${process.env.REACT_APP_API_URL}/accounts/update-email`, requestOptions)
    .then(res => {
      if (res.status === 200) {
        successToast();
        return Promise.resolve();
      } else {
        const error = (res && res.message) || res.statusText;
        errorToast();
        return Promise.reject(error);
      }
    });
}

function updateAccountInfo(data) {
  const requestOptions = {
    method: 'PUT',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
    body: JSON.stringify(data)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/accounts/me/details`, requestOptions)
    .then(res => isTokenExpired(res))
    .then(res => {
      if (res.status === 200) {
        return 'Your account infos has been modified!'
      } else {
        const error = (res && res.message) || res.statusText;
        return Promise.reject(error);
      }
    });
}

function getAccountInfo() {
  const requestOptions = {
    method: 'GET',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
  };

  return fetch(`${process.env.REACT_APP_API_URL}/accounts/me/details`, requestOptions)
    .then(res => isTokenExpired(res, false))
    .then(res => {
      if (res.status === 200) {
        return res.json()
      } else {
        const error = (res && res.message) || res.statusText;
        return Promise.reject(error);
      }
    });
}

function isTokenExpired(response, reload = true) {
  if (response.status === 401) {
    // auto logout if 401 response returned from api
    secureLocalStorage.removeItem('user');
    // eslint-disable-next-line no-restricted-globals
    location.reload(reload);
  }
return response;
};

function fetchAssociateTags(key, value, loaderFunction) {
  const requestOptions = {
    method: 'GET',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
  };

  return fetch(`${process.env.REACT_APP_API_URL}/references/${key}/find?name=${value}`, requestOptions)
    .then(res => isTokenExpired(res))
    .then(res => {
      if (res.status < 300) {
        return res.json()
      } else {
        const error = (res && res.message) || res.statusText;
        loaderFunction();
        return Promise.reject(error);
      }
    });
}