import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from '@mui/material/styles';
import { Button, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { userServices } from "../../services";

const PREFIX = 'ValidateAccount';

const classes = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
  whiteTitle: `${PREFIX}-whiteTitle`,
  textColor: `${PREFIX}-color`,
  buttonStyle: `${PREFIX}-buttonStyle`,
  marginLoadingText: `${PREFIX}-marginLoadingText`
};

const ValidateAccountContainer = styled('div')(({ theme }) => {
  return ({
    [`& .${classes.root}`]: {
      width: '100%',
      height: 'calc(100vh - 230px - 56px)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    [`& .${classes.title}`]: {
      position: 'absolute',
      top: '40%',
      left: '50%',
      transform: 'translate(-50%, -40%)',
      textAlign: 'center',
      width: '100%',
      maxWidth: '650px',
      padding: '1rem',
    },
    [`& .${classes.whiteTitle}`]: {
      position: 'absolute',
      top: '40%',
      left: '50%',
      transform: 'translate(-50%, -40%)',
      textAlign: 'center',
      color: 'white',
      width: '100%',
      maxWidth: '500px',
      padding: '1rem',
    },
    [`& .${classes.textColor}`]: {
      color: 'white'
    },
    [`& .${classes.buttonStyle}`]: {
      marginTop: '1rem',
      width: '200px'
    },
    [`& .${classes.marginLoadingText}`]: {
      marginBottom: '1rem',
    },
  })
});

const VALIDATION_STATUS = {
  IN_PROGRESS: 'IN_PROGRESS',
  VALIDATED: 'VALIDATED',
  ERROR: 'ERROR',
}

export default function ValidateAccount() {
  const { t } = useTranslation();
  const history = useNavigate();
  const { key } = useParams();
  const [validation, setValidation] = useState(VALIDATION_STATUS.IN_PROGRESS);
  const user = useSelector((state) => state.user.currentUser)

  function goToHome() {
    history('/');
  }

  useEffect(() => {
    const inter = setTimeout(() => userServices.validateAccount(key).then(() => {
      setValidation(VALIDATION_STATUS.VALIDATED);
      clearTimeout(inter);
    }).catch((error) => {
      setValidation(VALIDATION_STATUS.ERROR);
      clearTimeout(inter);
    }), 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ValidateAccountContainer>
      <div className={classes.root}>
        {validation === VALIDATION_STATUS.IN_PROGRESS &&
          <div className={classes.title}>
            <Typography variant="h4" className={classes.marginLoadingText}>
              {t('account.validation.validating')}
            </Typography>
            <CircularProgress size={50} />
          </div>
        }
        {validation === VALIDATION_STATUS.VALIDATED &&
          <div className={classes.whiteTitle}>
            <h2 className={classes.textColor}>
              {t('account.validation.validated')}
            </h2>
            <Button
              variant="contained"
              color="primary"
              onClick={goToHome}
              className={classes.buttonStyle}
            >
              {t('account.validation.home')}
            </Button>
          </div>
        }
        {validation === VALIDATION_STATUS.ERROR &&
          <div className={classes.whiteTitle}>
            <h2 className={classes.textColor}>
              {user && user.account && user.account.validated ?
                t('account.validation.linkError')
                :
                t('account.validation.error')
              }
            </h2>
            <Button
              variant="contained"
              color="primary"
              onClick={goToHome}
              className={classes.buttonStyle}
            >
              {t('account.validation.home')}
            </Button>
          </div>
        }
      </div>
    </ValidateAccountContainer>
  );
}
