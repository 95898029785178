import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

const PREFIX = 'AvatarCreator';

const classes = {
  updating: `${PREFIX}-updating`,
};

const Root = styled('div')((
  {
    theme
  }
) => ({

  [`& .${classes.updating}`]: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    width: '260px',
    height: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const AvatarCreator = (props) => {
  const { t } = useTranslation();
  const [displayIframe, setDisplayIframe] = useState(true);
  const updatingAvatar = useSelector(state => state.user.updatingAvatar);
  const locker = useRef(false);

  React.useEffect(() => {
    const subdomain = 'fanartzone'; // Replace with your custom subdomain
    const frame = document.getElementById('frame');

    frame.src = `https://${subdomain}.readyplayer.me/avatar?frameApi`;


    window.addEventListener('message', subscribe);
    document.addEventListener('message', subscribe);

    return () => {
      window.removeEventListener('message', subscribe);
      document.removeEventListener('message', subscribe);
    }
  }, [])

  function subscribe(event) {
    const json = parse(event);
    if (json?.source !== 'readyplayerme') {
      return;
    }

    // Susbribe to all events sent from Ready Player Me once frame is ready
    if (json.eventName === 'v1.frame.ready') {
      frame.contentWindow.postMessage(
        JSON.stringify({
          target: 'readyplayerme',
          type: 'subscribe',
          eventName: 'v1.**'
        }),
        '*'
      );
    }

    // Get avatar GLB URL
    if (json.eventName === 'v1.avatar.exported' && !locker.current) {
      // url in data.url et userID in data.userId
      props.setAvatarInformations(json.data)
      locker.current = true;
      setDisplayIframe(false);
    }

    // Get user id
    if (json.eventName === 'v1.user.set') {
      console.log(`User with id ${json.data.id} set: ${JSON.stringify(json)}`);
    }
  }

  function parse(event) {
    try {
      return JSON.parse(event.data);
    } catch (error) {
      // console.log("error RPM =>", error);
      // if ((typeof event.data === 'string' || event.data instanceof String) && event.data.includes('.glb')) {
      // avatar has been modified, only url is sent again in data object
      // console.log("event data =>", event.data)
      // props.setAvatarInformations(event.data)
      // }
      return null;
    }
  }

  useEffect(() => {
    document.getElementById('frame').hidden = !displayIframe;
  }, [displayIframe])

  return (
    <Root style={{height: '100%'}}>
      {updatingAvatar &&
        <div className={classes.updating}>
          <Typography variant="h4">{t('account.updatingAvatar')}</Typography>
          <CircularProgress size={50} />
        </div>
      }
      <iframe id="frame" className="frame" allow="camera *; microphone *; clipboard-write" width="100%" height="100%"></iframe>
    </Root>
  )
}

export default AvatarCreator;