import { Upload } from "antd";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import AddOutlined from "@mui/icons-material/AddOutlined";
import "./story.css";
import { useDispatch, useSelector } from "react-redux";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { TextField } from "@mui/material";
import {
  cleanUserData,
  saveFile,
  saveMessage,
} from "../../../reducers/campaigns/campaignsSlice";

const PREFIX = "Story";

const classes = {
  rootContainer: `${PREFIX}-rootContainer`,
  radiosContainer: `${PREFIX}-radiosContainer`,
  videoDurationText: `${PREFIX}-videoDurationText`,
  uploadText: `${PREFIX}-uploadText`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.radiosContainer}`]: {
    margin: "1rem",
  },
  [`& .${classes.radiosContainer}`]: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "inherit",
  },
  [`& .${classes.videoDurationText}`]: {
    paddingLeft: "22px",
  },
  [`& .${classes.uploadText}`]: {
    marginTop: 8,
  },
}));

const GoldenRadio = (props) => <Radio color="default" {...props} />;

export default function MyStory(props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.campaign.userData);
  const customMessage = useSelector(
    (state) => state.campaign.userData.customMessage
  );
  const currentMode = useSelector((state) => state.campaign.userData.type);
  const [fileList, setFileList] = useState([]);
  const [videoDuration, setVideoDuration] = useState(-1);
  const [mode, setMode] = useState("video");
  const prevMode = useRef();

  useEffect(() => {
    if (
      data?.fileList?.length > 0 &&
      (currentMode === "video" || currentMode === "image")
    ) {
      props.disableButtons(false);
    } else if (currentMode === "message" && customMessage?.length > 0) {
      props.disableButtons(false);
    } else {
      props.disableButtons(true);
    }
  }, [currentMode, data, customMessage]);

  const handleFileChange = (info) => {
    let tmp = [];
    if (info.fileList && info.fileList.length > 0) {
      tmp.push({
        uid: -1,
        name: info.file.name,
        status: "done",
        url: URL.createObjectURL(info.file),
        // thumbUrl: `${URL.createObjectURL(info.file)}#t=5`,
        file: info.file,
      });
      setFileList(tmp);
      dispatch(saveFile(tmp[0].file, mode));
      props.disableButtons(false);
    } else {
      setFileList([]);
      setVideoDuration(-1);
      props.disableButtons(true);
    }
  };

  const setMetadata = ({ duration }) => {
    if (
      props.conf.videoMaxDuration > 0 &&
      duration >= props.conf.videoMaxDuration
    ) {
      setFileList([]);
      setVideoDuration(-1);
      enqueueSnackbar(
        `${
          t("landing.story-duration-error-start") +
          props.conf.videoMaxDuration +
          t("landing.story-duration-error-end")
        }`,
        {
          variant: "error",
          autoHideDuration: 3000,
        }
      );
    } else {
      setVideoDuration(duration);
    }
  };

  const fileProps = {
    onChange: handleFileChange,
    // accept: 'video/*',
    multiple: false,
    beforeUpload: (file) => {
      setFileList([
        {
          uid: -1,
          name: file.name,
          status: "done",
          url: URL.createObjectURL(file),
          // thumbUrl: 'https://picsum.photos/200/300',
          file,
        },
      ]);
      return false;
    },
    onRemove: handleFileChange,
    listType: "picture-card",
  };

  useEffect(() => {
    if (mode !== prevMode.current) {
      setFileList([]);
      setVideoDuration(-1);
      props.disableButtons(true);
      dispatch(cleanUserData(mode));
    }
    prevMode.current = mode;
  }, [mode]);

  return (
    <Root>
      <div className={classes.rootContainer}>
        <canvas id="canvas" style={{ display: "none" }}></canvas>
        <FormControl variant="standard" component="fieldset">
          <FormLabel component="legend">Content type</FormLabel>
          <RadioGroup
            aria-label="contentType"
            name="contentType"
            value={mode}
            onChange={(e) => setMode(e.target.value)}
            className={classes.radiosContainer}
          >
            <FormControlLabel
              value="video"
              control={
                <GoldenRadio
                  color="primary"
                  classes={{
                    root: classes.root,
                    checked: classes.checked,
                  }}
                />
              }
              label="Video"
            />
            <FormControlLabel
              value="image"
              control={
                <GoldenRadio
                  color="primary"
                  classes={{
                    root: classes.root,
                    checked: classes.checked,
                  }}
                />
              }
              label="Image"
            />
            <FormControlLabel
              value="message"
              control={
                <GoldenRadio
                  color="primary"
                  classes={{
                    root: classes.root,
                    checked: classes.checked,
                  }}
                />
              }
              label="Message"
            />
          </RadioGroup>
        </FormControl>
        {mode === "video" || mode === "image" ? (
          <div>
            <div>
              {t("landing.story-duration")}
              {mode === "video" && props.conf.videoMaxDuration > 0 && (
                <div>
                  {`${t("landing.story-duration-start")} ${
                    props.conf.videoMaxDuration
                  } ${t("landing.story-duration-end")}`}
                </div>
              )}
            </div>
            <div className="single-input-container">
              {fileList[0] && mode === "video" && (
                <video
                  preload="metadata"
                  controls={true}
                  width="0"
                  onLoadedMetadata={(e) => {
                    setMetadata({
                      duration: e.target.duration,
                    });
                    //URL.revokeObjectURL(fileList[0]);
                  }}
                >
                  <source src={fileList[0].url} type={fileList[0].type} />
                </video>
              )}
              <Upload
                {...fileProps}
                fileList={fileList}
                accept={mode === "video" ? "video/*" : "image/*"}
              >
                {fileList.length === 0 && (
                  <div className="upload">
                    <AddOutlined />
                    <div className={classes.uploadText}>
                      {t("landing.story-video-deposit")}
                    </div>
                  </div>
                )}
              </Upload>
              {mode === "video" && (
                <>
                  <span id="duration"></span>
                  {videoDuration > 0 && (
                    <div className={classes.videoDurationText}>
                      {`${t(
                        "landing.story-duration-user"
                      )} ${videoDuration} ${t("landing.story-duration-end")}`}
                    </div>
                  )}
                </>
              )}
            </div>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="customMessage"
              label={t("landing.addCustomMessage")}
              name="customMessage"
              defaultValue={customMessage}
              onChange={(e) => dispatch(saveMessage(e.target.value))}
            />
          </div>
        ) : (
          <div>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="customMessage"
              label={t("landing.addCustomMessage")}
              name="customMessage"
              defaultValue={customMessage}
              onChange={(e) => dispatch(saveMessage(e.target.value))}
            />
          </div>
        )}
      </div>
    </Root>
  );
}
