import React from 'react';
import { styled, Typography } from '@mui/material';
import ContentCard from '../contentCard/ContentCard';

const PREFIX = 'CardsDisplayer';

const classes = {
  root: `${PREFIX}-root`,
  cardsDisplayer: `${PREFIX}-cardsDisplayer`,
};

const CardsDisplayerContainer = styled('div')(({ theme }) => {
  return ({
    [`& .${classes.root}`]: {
      margin: '0.5rem',
    },
    [`& .${classes.cardsDisplayer}`]: {
      marginTop: '0.5rem',
      height: '165px',
      display: 'flex',
      width: '100%',
      overflow: 'auto',
      paddingBottom: '10px',
      '&::-webkit-scrollbar': {
        width: '5px',
        height: '5px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.primary.main,
        borderRadius: '3px',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: 'rgb(181 154 79 / 58%)',
        bordeRadius: '10px',
      },
    },
  })
});

const CardsDisplayer = (props) => {
  if (props.elements?.length === 0) {
    return null;
  } else {
    return (
      <CardsDisplayerContainer>
        <div className={classes.root}>
          <Typography variant="h5">
            {props.title}
          </Typography>
          <div className={classes.cardsDisplayer}>
            {props.elements.map((item, index) => (
              <ContentCard
                handleBackdrop={props.handleBackdrop}
                element={item}
                key={`card-${props.id}-${index}`}
                isVip={props.id === 'vipartzones'}
              />
            ))}
          </div>
        </div>
      </CardsDisplayerContainer>
    )
  }
}

export default CardsDisplayer;