import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import PasswordStrengthBar from 'react-password-strength-bar';
import zxcvbn from 'zxcvbn';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { userServices } from '../../services';

const PREFIX = 'updatePassword';

const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  logo: `${PREFIX}-logo`,
  form: `${PREFIX}-form`,
  submit: `${PREFIX}-submit`,
  margin: `${PREFIX}-margin`,
  inputMargin: `${PREFIX}-inputMargin`,
  buttonLoader: `${PREFIX}-buttonLoader`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    height: '100vh',
  },

  [`& .${classes.paper}`]: {
    padding: theme.spacing(3, 3),
    display: 'flex',
    margin: '0 auto',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '400px',
    width: '100%',
  },

  [`& .${classes.logo}`]: {
    margin: theme.spacing(1),
    backgroundColor: "transparent",
    display: "none",
    maxHeight: '250px',
    marginBottom: '2rem',
    [theme.breakpoints.only('xs')]: {
      display: 'block',
    },
  },

  [`& .${classes.form}`]: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    position: 'relative',
    top: 0,
  },

  [`& .${classes.submit}`]: {
    margin: theme.spacing(3, 0, 2),
  },

  [`& .${classes.margin}`]: {
    width: '100%',
  },

  [`& .${classes.inputMargin}`]: {
    marginBottom: '1rem',
  },

  [`& .${classes.buttonLoader}`]: {
    color: 'inherit',
  }
}));

export default function ChangePasswordForm(props) {
  const { enqueueSnackbar } = useSnackbar();

  const { t, i18n } = useTranslation();
  const [newPassword, setNewPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [passwordScore, setPasswordScore] = useState(0);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const loading = useSelector(state => state.user.loading);
  const success = useSelector(state => state.user.success);
  const error = useSelector(state => state.user.error);
  const dispatch = useDispatch();

  useEffect(() => {
    if (success) {
      enqueueSnackbar(success, { variant: 'success', });
    }
    if (error) {
      enqueueSnackbar(error, { variant: 'error', })
    }
  }, [dispatch, enqueueSnackbar, success, error]);

  function handleChange(e, key) {
    if (key === "newPassword") {
      let score = 0;
      let result = null;
      result = zxcvbn(e.target.value, []);
      ({ score } = result)
      setPasswordScore(score);
      setNewPassword(e.target.value);
    } else if (key === "oldPassword") {
      setOldPassword(e.target.value);
    } else {
      setConfirmPassword(e.target.value);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    userServices.updatePasswordFromAccount(oldPassword, newPassword)
      .then(() => enqueueSnackbar(t('toasts.updatePasswordFromAccount'), { variant: 'success' }))
      .catch(() => enqueueSnackbar(t('toasts.updatePasswordFailed'), { variant: 'error' }));
  }

  function handleClickShowPassword(e, key) {
    if (key === "showPassword") {
      setShowPassword(!showPassword);
    } else if (key === "showOldPassword") {
      setShowOldPassword(!showOldPassword);
    } else {
      setShowConfirmPassword(!showConfirmPassword);
    }
  }

  function handleMouseDownPassword(event) {
    event.preventDefault();
  };

  function handleForm() {
    return (newPassword.length > 0 && confirmPassword.length > 0 && newPassword === confirmPassword && passwordScore >= 2 && setOldPassword.length > 0) ? false : true
  }

  return (
    <Root>
      <div className={classes.paper}>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <FormControl variant="standard" className={classes.margin}>
            <InputLabel htmlFor="password">{t('account.updatePasswordForm.currentPassword')}</InputLabel>
            <Input
              required
              fullWidth
              name="oldPassword"
              type={showOldPassword ? 'text' : 'password'}
              id="oldPassword"
              autoComplete="oldPassword"
              className={classes.inputMargin}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle old password visibility"
                    onClick={(e) => handleClickShowPassword(e, 'showOldPassword')}
                    onMouseDown={handleMouseDownPassword}
                    size="large">
                    {showOldPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              onChange={(e) => handleChange(e, "oldPassword")}
            />
          </FormControl>
          <FormControl variant="standard" className={classes.margin}>
            <InputLabel htmlFor="password">{t('account.updatePasswordForm.newPassword')}</InputLabel>
            <Input
              required
              fullWidth
              name="newPassword"
              type={showPassword ? 'text' : 'password'}
              id="newPassword"
              autoComplete="current-password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle newPassword visibility"
                    onClick={(e) => handleClickShowPassword(e, 'showPassword')}
                    onMouseDown={handleMouseDownPassword}
                    size="large">
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              onChange={(e) => handleChange(e, "newPassword")}
            />
            <PasswordStrengthBar password={newPassword} />
          </FormControl>
          <FormControl variant="standard" className={classes.margin}>
            <InputLabel htmlFor="password">{t('account.updatePasswordForm.confirmNewPassword')}</InputLabel>
            <Input
              required
              fullWidth
              name="confirmPassword"
              type={showConfirmPassword ? 'text' : 'password'}
              id="confirmPassword"
              autoComplete="current-password"
              className={classes.inputMargin}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle confirmPassword visibility"
                    onClick={(e) => handleClickShowPassword(e, 'showConfirmPassword')}
                    onMouseDown={handleMouseDownPassword}
                    size="large">
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              onChange={(e) => handleChange(e, "confirmPassword")}
            />
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={handleForm()}
          >
            {loading ? <CircularProgress size={28}
              className={classes.buttonLoader} /> : t('account.updatePasswordForm.updateButton')}
          </Button>
        </form>
      </div>
    </Root>
  );
}
