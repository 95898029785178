import { createSlice } from '@reduxjs/toolkit'
import { getCampaign, checkVipCode, save } from './campaignsThunks';

function initializeCampaignsState() {
  let initialState = {
    // modals
    modalType: null,
    //getCampaign
    fetched: false,
    fetching: false,
    fetchError: false,
    campaignInfos: null,
    userData: {},
    //checkCode
    codeVipChecking: false,
    error: null,
    codeVip: false,
    //saveCampaign
    save: null,
    userCampaign: null,
    reason: null,
  }
  return initialState;
}

export const campaignSlice = createSlice({
  name: 'campaign',
  initialState: initializeCampaignsState(),
  reducers: {
    closeModal: (state) => {
      state.modalType = null;
    },
    openModal: (state, action) => {
      state.modalType = action.payload;
    },
    saveFile: (state, action) => {
      state.userData.fileList = [action.payload];
    },
    saveMessage: (state, action) => {
      state.userData.customMessage = action.payload;
    },
    cleanUserData: (state, action) => {
      state.userData.fileList = [];
      state.userData.type = action.payload;
    },
    saveForm: (state, action) => {
      state.userData = {...state.userData, ...action.payload};
    },
    saveCgu: (state, action) => {
      state.userData.cgu = action.payload;
    },
    saveSpreadData: (state, action) => {
      state.userData.spreadData = action.payload;
    },
    saveNewsletter: (state, action) => {
      state.userData.newsletter = action.payload;
    },
    resetCampaign: (state) => {
      state.save = null;
      state.reason = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCampaign.pending, (state) => {
        state.fetching = true;
      })
      .addCase(getCampaign.fulfilled, (state, action) => {
        state.campaignInfos = action.payload;
        state.fetching = false;
        state.fetched = true;
      })
      .addCase(getCampaign.rejected, (state) => {
        state.fetching = false;
        state.fetchError = false;
      })
      .addCase(checkVipCode.pending, (state) => {
        state.codeVipChecking = true;
        state.error = null;
      })
      .addCase(checkVipCode.fulfilled, (state) => {
        state.codeVip = true;
        state.codeVipChecking = false;
        state.fetched = true;
      })
      .addCase(checkVipCode.rejected, (state, action) => {
        state.codeVip = false;
        state.codeVipChecking = false;
        state.error = action.payload;
      })
      .addCase(save.pending, (state) => {
        state.save = 'IN_PROGRESS';
      })
      .addCase(save.fulfilled, (state, action) => {
        state.save = 'OK';
        state.userCampaign = action.payload;
      })
      .addCase(save.rejected, (state, action) => {
        state.save = 'KO';
        state.reason = action.payload;
      })
  },
})

// Action creators are generated for each case reducer function
export const {
  saveFile,
  saveMessage,
  cleanUserData,
  saveForm,
  saveCgu,
  saveSpreadData,
  saveNewsletter,
  resetCampaign,
  openModal,
  closeModal,
} = campaignSlice.actions

export default campaignSlice.reducer