import { createAsyncThunk } from "@reduxjs/toolkit";
import { campaignService } from "../../services"
import { campaign as constants } from "../../constants";

export const getCampaign = createAsyncThunk(
  'campaign/get',
  async (infos, { rejectWithValue }) => {

    const pathNameToSend = infos.pathname.split('/')[1];
    const isVip = infos.pathname.split('/').length > 2 && infos.pathname.split('/')[2] === 'vip' ? '&vip=true' : '';

    const response = await campaignService.get(infos.lang, pathNameToSend, isVip)
    if (!response)
      return rejectWithValue('offline')

    let tmp = {};
    await response.json().then((conf) => {
      let tmpPathname = '/' + infos.pathname.split('/')[infos.pathname.split('/').length - 1];
      tmp.uniqueKey = conf.uniqueKey;
      tmp.state = conf.state; // NOT_STARTED, ON_GOING, TERMINATED, ARCHIVED
      tmp.startDate = conf.startDate;
      tmp.endDate = conf.endDate;
      tmp.description = conf.description;
      tmp.imageForm = conf.imageForm;
      tmp.imageFormUrl = conf.imageFormUrl;
      tmp.imageHeader = conf.imageHeader;
      tmp.headerUrl = conf.headerUrl;
      tmp.targetLabel = conf.targetLabel;
      tmp.name = conf.name;
      tmp.fan = conf.fan;
      tmp.vip = conf.vip;
      tmp.family = conf.family;
      switch (tmpPathname) {
        case "/vip":
          tmp.videoMaxDuration = conf.durationVideoVip;
          break;
        case "/family":
          tmp.videoMaxDuration = conf.durationVideoFamily;
          break;
        default:
          tmp.videoMaxDuration = conf.durationVideoFan;
          break;
      }
      tmp.pathname = infos.pathname.split('/').length === 2 ? 'fan' : infos.pathname.split('/')[infos.pathname.split('/').length - 1];
    });
    return tmp;
  }
)

export const checkVipCode = createAsyncThunk(
  'campaign/checkVipCode',
  async (infos, { rejectWithValue }) => {
    const response = await campaignService.checkCode(infos.code, infos.uniqueKey)
    if (!response)
      return rejectWithValue('offline')
    if (response.status > 300) {
      return rejectWithValue(response.status === 403 ? "landing.vip.incorrectCode" : "landing.vip.unknownError")
    }
    return response
  }
)

export const save = createAsyncThunk(
  'campaign/save',
  async (campaignInfos, { rejectWithValue }) => {
    const { userCampaign, role, uniqueKey, handleProgress } = campaignInfos;

    const formData = new FormData();
    formData.append('content', userCampaign.type);
    if (userCampaign.customMessage)
      formData.append('message', userCampaign.customMessage);
    if (userCampaign.type === 'video') {
      formData.append('videoFile', userCampaign.fileList[0], userCampaign.fileList[0].name);
    } else if (userCampaign.type === 'image') {
      formData.append('imageFile', userCampaign.fileList[0], userCampaign.fileList[0].name);
    }
    formData.append('firstName', userCampaign.firstname);
    formData.append('lastName', userCampaign.lastname);
    formData.append('email', userCampaign.email);
    formData.append('newsletter', userCampaign.newsletter || false);
    formData.append('dataSpread', userCampaign.spreadData || false);
    formData.append('role', role);


    const response = await campaignService.save(uniqueKey, formData, handleProgress)
    if (!response)
      return rejectWithValue('offline')
    if (response.status > 300)
      return rejectWithValue(response.status === 409 ? constants.CAMPAIGN_USER_ALREADY_EXISTS : constants.CAMPAIGN_USER_SAVE_FAILURE)
    return response.data;
  }
)