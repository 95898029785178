import React from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';

function PrivateRouteWrapper() {
  const location = useLocation()

  if (localStorage && localStorage.getItem('user')) {
    localStorage.removeItem('user');
  }
  if (!secureLocalStorage || (secureLocalStorage && !secureLocalStorage.getItem('user'))) {
    // not logged in so redirect to login page with the return url
    return <Navigate to={{ pathname: '/login', state: { from: location, isLoginRequested: true } }} />
  }

  // logged in so return component
  return <Outlet />
}

export { PrivateRouteWrapper };