import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useParams } from "react-router-dom";
import { resetPassword } from '../../reducers/user/userThunks';
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Logo from "../../assets/logo_ads.webp";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import PasswordStrengthBar from 'react-password-strength-bar';
import zxcvbn from 'zxcvbn';
import { CircularProgress } from '@mui/material';
import { useTranslation } from "react-i18next";
import { Copyright } from '../../helpers';

const PREFIX = 'ChangePasswordForm';

const classes = {
  paper: `${PREFIX}-paper`,
  image: `${PREFIX}-image`,
  logo: `${PREFIX}-logo`,
  form: `${PREFIX}-form`,
  submit: `${PREFIX}-submit`,
  fullWidth: `${PREFIX}-fullWidth`,
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.paper}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '650px',
    width: '100%',
    height: 'calc(100vh - 230px - 56px)',
    padding: '1rem',
    margin: '0 auto',
  },

  [`& .${classes.image}`]: {
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#303030',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    marginBottom: '4rem',
    width: '100%',
    maxWidth: '650px',
  },

  [`& .${classes.logo}`]: {
    margin: theme.spacing(1),
    backgroundColor: "transparent",
    display: "none",
    maxHeight: '250px',
    marginBottom: '2rem',
    [theme.breakpoints.only('xs')]: {
      display: 'block',
    },
  },

  [`& .${classes.form}`]: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    position: 'relative',
    top: 0,
  },

  [`& .${classes.submit}`]: {
    margin: theme.spacing(3, 0, 2),
  },

  [`& .${classes.fullWidth}`]: {
    width: '100%'
  }
}));

export default function ChangePasswordForm() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { key } = useParams();

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [passwordScore, setPasswordScore] = useState(0);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const loading = useSelector(state => state.user.loading);
  const success = useSelector(state => state.user.success);
  const error = useSelector(state => state.user.error);

  useEffect(() => {
    if (success) {
      enqueueSnackbar(success, { variant: 'success', autoHideDuration: 3000 });
    }
    if (error) {
      enqueueSnackbar(error, { variant: 'error', autoHideDuration: 3000 })
    }
  }, [dispatch, enqueueSnackbar, success, error]);

  function handleChange(e, key) {
    if (key === "newPassword") {
      let score = 0;
      let result = null;
      result = zxcvbn(e.target.value, []);
      ({ score } = result)
      setPasswordScore(score);
      setNewPassword(e.target.value);
    } else {
      setConfirmPassword(e.target.value);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
      try {
        const thunk = await dispatch(resetPassword({ key, password: newPassword })); // 'An error has occured, try again later'
        if (thunk.meta.requestStatus === 'rejected')
          throw new Error('offline')
        enqueueSnackbar(t('toasts.resetEmail'), { variant: 'success', autoHideDuration: 3000 });
      }
      catch {
        enqueueSnackbar(t('toasts.getInfosError'), { variant: 'error' });
      }
  }

  function handleClickShowPassword(e, key) {
    if (key === "showPassword") {
      setShowPassword(!showPassword);
    } else {
      setShowConfirmPassword(!showConfirmPassword);
    }
  }

  function handleMouseDownPassword(event) {
    event.preventDefault();
  };

  function handleForm() {
    return (newPassword.length > 0 && confirmPassword.length > 0 && newPassword === confirmPassword && passwordScore >= 2) ? false : true
  }

  return (
    <Root>
      <div className={classes.paper}>
        <img src={Logo} className={classes.image} />
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <FormControl variant="standard" className={classes.fullWidth}>
            <InputLabel htmlFor="password">{t("account.updatePasswordForm.newPassword")}</InputLabel>
            <Input
              required
              fullWidth
              name="newPassword"
              type={showPassword ? 'text' : 'password'}
              id="newPassword"
              autoComplete="current-password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle newPassword visibility"
                    onClick={(e) => handleClickShowPassword(e, 'showPassword')}
                    onMouseDown={handleMouseDownPassword}
                    size="large">
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              onChange={(e) => handleChange(e, "newPassword")}
            />
            <PasswordStrengthBar password={newPassword} />
          </FormControl>
          <FormControl variant="standard" className={classes.fullWidth}>
            <InputLabel htmlFor="password">{t("account.updatePasswordForm.confirmNewPassword")}</InputLabel>
            <Input
              required
              fullWidth
              name="confirmPassword"
              type={showConfirmPassword ? 'text' : 'password'}
              id="confirmPassword"
              autoComplete="current-password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle confirmPassword visibility"
                    onClick={(e) => handleClickShowPassword(e, 'showConfirmPassword')}
                    onMouseDown={handleMouseDownPassword}
                    size="large">
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              onChange={(e) => handleChange(e, "confirmPassword")}
            />
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={handleForm() || loading}
          >
            {loading ? <CircularProgress size={28} /> : t("account.updatePasswordForm.updateButton")}
          </Button>
          <Box mt={5}>
            <Copyright />
          </Box>
        </form>
      </div>
    </Root>
  );
}
