import secureLocalStorage from "react-secure-storage";

export function authHeader(isMultipart) {
  // return authorization header with jwt token
  let user = JSON.parse(secureLocalStorage.getItem('user'));

  if (user && user.token) {
    if (isMultipart) return {'Authorization': 'Bearer ' + user.token};
    return {'Authorization': 'Bearer ' + user.token, 'Content-Type': 'application/json;charset=UTF-8'};
  } else {
    if (isMultipart) return {};
    return {'Content-Type': 'application/json;charset=UTF-8'};
  }
}
