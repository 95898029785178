import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { getEntityExpariences, getHomePage } from '../../services';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Typography, CircularProgress, Backdrop, Slide } from '@mui/material';
import HeadersDisplayer from '../../components/headersDisplayer/HeadersDisplayer';
import CardsDisplayer from '../../components/cardsDisplayer/CardsDisplayer';
import BackdropCardInfo from '../../components/backdropCardInfo/BackdropCardInfo';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '../../reducers/campaigns/campaignsSlice';
import ResponsiveDialogManager from '../../components/responsiveDialogManager/ResponsiveDialogManager';
import EntityDisplayer from '../../components/entityDisplayer/EntityDisplayer';

const PREFIX = 'MainDisplayer';

const classes = {
  root: `${PREFIX}-root`,
  loadingContainer: `${PREFIX}-loadingContainer`,
  loadingTitle: `${PREFIX}-loadingTitle`,
  backdrop: `${PREFIX}-backdrop`,
  zoneBottomMenu: `${PREFIX}-zoneBottomMenu`,
  closeIconBackdrop: `${PREFIX}-closeIconBackdrop`,
};

const RootNoData = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    padding: '0',
    minHeight: 'calc(100vh - 190px - 51px)',
    position: 'relative',
    paddingBottom: '2rem',
    maxWidth: '950px',
    margin: '0 auto',
  },
  [`& .${classes.loadingContainer}`]: {
    textAlign: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  [`& .${classes.loadingTitle}`]: {
    marginBottom: '1rem'
  },
}));

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    padding: '0',
    minHeight: 'calc(100vh - 190px - 51px)',
    position: 'relative',
    paddingBottom: '2rem',
    maxWidth: '950px',
    margin: '0 auto',
    backgroundColor: 'black',
  },
  [`& .${classes.loadingContainer}`]: {
    textAlign: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  [`& .${classes.loadingTitle}`]: {
    marginBottom: '1rem'
  },
  [`& .${classes.backdrop}`]: {
    zIndex: 9999,
    color: '#fff',
  },
  [`& .${classes.zoneBottomMenu}`]: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '500px',
    },
    minHeight: '230px',
    position: 'absolute',
    bottom: '-1px',
    backgroundColor: "#303030",
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    display: 'flex',
    flexDirection: 'row',
  },
  [`& .${classes.closeIconBackdrop}`]: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    cursor: 'pointer',
  },
}));

const MainDisplayer = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const { t } = useTranslation();

  const [data, setData] = useState(null);
  const [backdropInfos, setBackdropInfos] = useState(null);
  const [fetchedEntity, setFetchedEntity] = useState({
    loading: false,
    data: null,
  });
  const isLoggedIn = useSelector(state => state.user.loggedIn)

  useEffect(() => {
    if (!isLoggedIn)
      if (location.pathname.includes('login')) {
        dispatch(openModal('login'))
      } else if (location.pathname.includes('register')) {
        dispatch(openModal('register'))
      }
  }, [location.pathname])

  useEffect(() => {
    getHomePage()
      .then(res => {
        setData({
          ...res,
          vipartzones: res.fanartzones.filter(item => item.vip),
          onlyFansArtZones: res.fanartzones.filter(item => item.fan)
        });
      })
      .catch(err => {
        enqueueSnackbar(t('toasts.fetchDataError'), { variant: 'error', });
      })
  }, [])

  const handleBackdrop = (element) => {
    if (element.expariences) {
        setFetchedEntity({ ...fetchedEntity, loading: true });
        getEntityExpariences(element.id)
          .then(res => {
            setFetchedEntity({ loading: false, data: res });
          })
          .catch(() => {
            enqueueSnackbar(t('toasts.fetchDataError'), { variant: 'error', });
            setFetchedEntity({loading: false, data: null});
          })
    } else {
      setBackdropInfos(element);
    }
  }

  if (!data) {
    return (
      <RootNoData>
        <div className={classes.root}>
          <div className={classes.loadingContainer}>
            <Typography
              className={classes.loadingTitle}
              variant="h5"
            >
              {t('home.loading')}
            </Typography>
            <CircularProgress size={50} />
          </div>
        </div>
      </RootNoData>
    )
  }
  return (
    <Root>
      <div className={classes.root}>
        <ResponsiveDialogManager
          fullScreen
          entityMode
          open={fetchedEntity.loading || fetchedEntity.data ? true : false}
          renderer={<EntityDisplayer data={fetchedEntity.data} handleBackdrop={handleBackdrop} />}
          title={fetchedEntity.data?.name || t('form.loading')}
          onClose={() => {
            setFetchedEntity({loading: false, data: null})
          }} />
        <Backdrop
          className={classes.backdrop}
          open={backdropInfos ? true : false}
          onClick={() => setBackdropInfos(null)}
        >
          <Slide direction="up" in={backdropInfos ? true : false} mountOnEnter unmountOnExit>
            <div className={classes.zoneBottomMenu} onClick={(e) => e.stopPropagation()}>
              <CloseIcon
                className={classes.closeIconBackdrop}
                onClick={() => setBackdropInfos(null)}
              />
              <BackdropCardInfo
                element={backdropInfos}
              />
            </div>
          </Slide>
        </Backdrop>
        <div>
          <HeadersDisplayer
            headerUrls={data.headers}
          />
          <CardsDisplayer
            title={t('home.currentExpariences')}
            elements={data.expariences}
            handleBackdrop={handleBackdrop}
            id="expariences"
          />
          <CardsDisplayer
            title={t('home.news')}
            elements={data.currentExpariences}
            handleBackdrop={handleBackdrop}
            id="currentExpariences"
          />
          <CardsDisplayer
            title={t('home.yourStoryFanArtZone')}
            handleBackdrop={handleBackdrop}
            elements={data.onlyFansArtZones}
            id="fanartzones"
          />
          <CardsDisplayer
            title={t('home.yourStoryVipArtZone')}
            handleBackdrop={handleBackdrop}
            elements={data.vipartzones}
            id="vipartzones"
          />
        </div>
      </div>
    </Root>
  )
}

export default MainDisplayer;