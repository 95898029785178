import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from "@stripe/react-stripe-js";

import 'antd/dist/antd.min.css';
import './i18n/i18n';
import { store, ViewportProvider } from './helpers';
import AppRoutes from './routes/AppRoutes';
import * as serviceWorker from './serviceWorker';
import { SnackbarProvider } from 'notistack';

import './assets/index.css';
import { ThemeProvider } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { theme } from "./helpers/theme";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';

import firebase from 'firebase/compat/app';
// v9 compat packages are API compatible with v8 code
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

if (typeof window !== 'undefined') window.store = store;

firebase.initializeApp({
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
});

const stripePromise = loadStripe('pk_test_51ITRnzDIgmisdqW4c6fNnBDI65DcOwbldOYZBYqz50Sb6yVV9urmfYkaeHD0WLVYq7u1nE3Nel7FiHefYAUlZnQR00WYt1Gui9');

// eslint-disable-next-line react/jsx-filename-extension
const App = () => (
  // eslint-disable-next-line react/jsx-filename-extension
  <Provider store={store}>
    <Elements stripe={stripePromise}>
      <AppRoutes />
    </Elements>
  </Provider>
);

const notistackRef = React.createRef();
const onClickDismiss = key => () => {
  notistackRef.current.closeSnackbar(key);
}

const useStyles = makeStyles((theme) => ({
  error: {
    backgroundColor: '#484848 !important',
    color: '#f55c5c !important',
  },
  success: {
    backgroundColor: '#484848 !important',
    color: '#CEB673 !important',
  },
  info: {
    backgroundColor: '#484848 !important',
    color: '#a5893e !important',
  },
  warning: {
    backgroundColor: '#484848 !important',
    color: '#f5925c !important',
  },
}));

function ZeApp() {
  const classes = useStyles();
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      ref={notistackRef}
      action={(key) => (
        <IconButton onClick={onClickDismiss(key)} size="large">
          <CloseIcon />
        </IconButton>
      )}
      classes={{
        variantError: classes.error,
        variantSuccess: classes.success,
        variantInfo: classes.info,
        variantWarning: classes.warning,
      }}
    >
      <ViewportProvider>
        <App />
      </ViewportProvider>
    </SnackbarProvider>
  );
}

function MyApp() {
  return (
    <ThemeProvider theme={theme}>
      <ZeApp />
    </ThemeProvider>
  )
}

ReactDOM.render(
  <React.Fragment>
    <MyApp />
  </React.Fragment>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
