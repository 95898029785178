import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Account from '../views/account/Account';
import LandingPage from '../views/LandingPage/LandingPage';
import NotFound from '../components/NotFound';
import TopMenu from '../components/common/TopMenu';
import MailValidation from "../views/mail-validation/MailValidation";
import '../i18n/i18n.js';
import ModalManager from '../components/modalManager/ModalManager';
import ValidateAccount from "../views/account/ValidateAccount";
import Footer from "../components/footer/Footer";
import MainDisplayer from '../views/Main/MainDisplayer';
import { PrivateRouteWrapper } from './routewrappers/PrivateRouteWrapper';
import ChangePasswordForm from '../components/forms/ChangePasswordForm';
import AppBanner from '../components/appBanner/AppBanner';

const AppRoutes = () => (
  <BrowserRouter>
    <ModalManager />
      <TopMenu />
      <AppBanner />
      <Routes>
        <Route path="/" element={<MainDisplayer />} />
        <Route path="/" element={<PrivateRouteWrapper />}>
          <Route path="/account" element={<Account />} />
        </Route>
        <Route path="/login" element={<MainDisplayer/>} />
        <Route path="/register" element={<MainDisplayer/>} />
        <Route path="/account/validate/:key" element={<ValidateAccount />} />
        <Route path="/reset-password/:key" element={<ChangePasswordForm />} />
        <Route path="/sharePage" element={<MainDisplayer />} />
        <Route path="/:name" element={<LandingPage />} />
        <Route path="/:name/vip" element={<LandingPage />} />
        <Route path="/:name/players" element={<LandingPage />} />
        <Route path="/:name/family" element={<LandingPage />} />
        <Route path="/:name/friends" element={<LandingPage />} />
        <Route path="/:name/campaign-user/mail-validation/:key" element={<MailValidation />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    <Footer />
  </BrowserRouter>
);

export default AppRoutes;
