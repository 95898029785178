import { createAsyncThunk } from "@reduxjs/toolkit";
import { userServices } from "../../services"

export const getAccountInfo = createAsyncThunk(
  'user/getAccountInfo',
  async (_, { rejectWithValue }) => {
    const response = await userServices.getAccountInfo()
    if (!response)
      return rejectWithValue('offline')
    if (response.status < 300)
      return rejectWithValue('An error has occured, try again later')
    return response;
  }
)

export const updateAccountInfo = createAsyncThunk(
  'user/updateAccountInfos',
  async (userInfos, { rejectWithValue }) => {

    if (userInfos.birthDate === "")
      delete userInfos.birthDate;

    const response = await userServices.updateAccountInfo(userInfos)
    if (!response)
      return rejectWithValue('offline')
    if (response.status < 300)
      return rejectWithValue('An error has occured, try again later')
    return userInfos;
  }
)

export const socialLogin = createAsyncThunk(
  'user/socialLogin',
  async (providerInfo, { rejectWithValue }) => {
    const response = await userServices.socialLogin(providerInfo)
    if (!response)
      return rejectWithValue('offline')
    return response
  }
)

export const login = createAsyncThunk(
  'user/login',
  async (userInfos, { rejectWithValue }) => {
    const response = await userServices.login(userInfos.login, userInfos.password)
    if (!response)
      return rejectWithValue('offline')
    return response;
  }
)

export const register = createAsyncThunk(
  'user/register',
  async (userInfos, { rejectWithValue }) => {
    const response = await userServices.register(userInfos)
    if (!response)
      return rejectWithValue('offline')
    if (response.status < 300)
      return rejectWithValue('An error has occured, try again later')
    return response;
  }
)

export const updateAvatar = createAsyncThunk(
  'user/updateAvatar',
  async (img, { rejectWithValue }) => {
    const response = await userServices.updateAvatar(img)
    if (!response)
      return rejectWithValue('offline')
    if (response.status < 300)
      return rejectWithValue('An error has occured, try again later')
    return { data: response, old: img };
  }
)

export const recoverPassword = createAsyncThunk(
  'user/recoverPassword',
  async (email, { rejectWithValue }) => {
    const response = await userServices.recoverPassword(email)
    if (!response)
      return rejectWithValue('offline')
    return response
  }
)

export const resetPassword = createAsyncThunk(
  'user/resetPassword',
  async (infos, { rejectWithValue }) => {
    const response = await userServices.resetPassword(infos.key, infos.password)
    if (!response)
      return rejectWithValue('offline')
    return response
  }
)