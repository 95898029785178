import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import './topMenu.css'
import { useDispatch, useSelector } from "react-redux";
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { Avatar, Fade } from "@mui/material";
import { useViewport } from '../../helpers';
import PersonIcon from '@mui/icons-material/Person'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import { useTranslation } from 'react-i18next';
import { GroupWork } from "@mui/icons-material";
import { useNavigate } from 'react-router-dom';
import { getAccountInfo } from '../../reducers/user/userThunks';
import { logout } from '../../reducers/user/userSlice';
import { openModal } from '../../reducers/campaigns/campaignsSlice';
import { useRef } from 'react';

const PREFIX = 'TopMenu';

const classes = {
  root: `${PREFIX}-root`,
  ToolbarRoot: `${PREFIX}-ToolbarRoot`,
  paper: `${PREFIX}-paper`,
  appBar: `${PREFIX}-appBar`,
  appBarShift: `${PREFIX}-appBarShift`,
  menuButton: `${PREFIX}-menuButton`,
  hide: `${PREFIX}-hide`,
  popperStyle: `${PREFIX}-popperStyle`,
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  rightMenuContainer: `${PREFIX}-rightMenuContainer`,
  rightMenuContainerPosition: `${PREFIX}-rightMenuContainerPosition`,
  rightMenuContainerPositionMobile: `${PREFIX}-rightMenuContainerPositionMobile`,
  rightMenuContainerPositionDesktop: `${PREFIX}-rightMenuContainerPositionDesktop`,
  drawerHeader: `${PREFIX}-drawerHeader`,
  content: `${PREFIX}-content`,
  contentShift: `${PREFIX}-contentShift`,
  inputRoot: `${PREFIX}-inputRoot`,
  inputInput: `${PREFIX}-inputInput`,
  accountCircleIcon: `${PREFIX}-accountCircleIcon`,
  accountAvatar: `${PREFIX}-accountAvatar`,
  desktopTitleContainer: `${PREFIX}-desktopTitleContainer`,
  desktopTitle: `${PREFIX}-desktopTitle`,
  accountCircleIconDesktop: `${PREFIX}-accountCircleIconDesktop`,
  buttonMenuHeight: `${PREFIX}-buttonMenuHeight`,
  cursorPointer: `${PREFIX}-cursorPointer`,
  logout: `${PREFIX}-logout`,
  authButtonsContainer: `${PREFIX}-authButtonsContainer`,
  connectbuttons: `${PREFIX}-connectbuttons`,
  rightTopMenuDisplay: `${PREFIX}-rightTopMenuDisplay`,
  buttonSize: `${PREFIX}-buttonSize`,
  popperTextStyle: `${PREFIX}-popperTextStyle`,
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    height: '56px',
  },

  [`& .${classes.ToolbarRoot}`]: {
    justifyContent: 'space-between',
  },

  [`& .${classes.paper}`]: {
    marginRight: theme.spacing(2),
  },

  [`& .${classes.popperTextStyle}`]: {
    fontSize: '12px',
    color: 'white',
  },

  [`& .${classes.appBar}`]: {
    top: '-1px',
    backgroundColor: 'rgb(42,42,42)',
    color: '#D3AF5F',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  [`& .${classes.appBarShift}`]: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  [`& .${classes.menuButton}`]: {
    marginRight: theme.spacing(2),
  },

  [`& .${classes.hide}`]: {
    display: 'none',
  },

  [`& .${classes.popperStyle}`]: {
    position: 'relative',
    right: '3%',
    opacity: '1',
    backgroundColor: '#424242',
    transform: 'none',
    transformOrigin: 'center top',
    transition: 'opacity 267ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 178ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },

  [`& .${classes.drawer}`]: {
    width: drawerWidth,
    flexShrink: 0,
  },

  [`& .${classes.drawerPaper}`]: {
    width: drawerWidth,
    backgroundColor: '#303030'
  },

  [`& .${classes.rightMenuContainer}`]: {
    width: '80px',
  },

  [`& .${classes.rightMenuContainerPosition}`]: {
    position: 'absolute',
    right: '15px',
  },

  [`& .${classes.rightMenuContainerPositionMobile}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    right: 0,
  },

  [`& .${classes.rightMenuContainerPositionDesktop}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },

  [`& .${classes.drawerHeader}`]: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'center',
  },

  [`& .${classes.content}`]: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },

  [`& .${classes.contentShift}`]: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },

  [`& .${classes.inputRoot}`]: {
    color: 'inherit',
  },

  [`& .${classes.inputInput}`]: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '20ch',
      '&:focus': {
        width: '26ch',
      },
    },
    [theme.breakpoints.between('xs', 'md')]: {
      width: '12ch',
      '&:focus': {
        width: '18ch',
      },
    },
  },

  [`& .${classes.accountCircleIcon}`]: {
    width: '35px',
    height: '35px',
    transform: 'translateY(5px)'
  },

  [`& .${classes.accountAvatar}`]: {
    display: 'flex',
    alignItems: 'center'
  },

  [`& .${classes.desktopTitleContainer}`]: {
    display: 'flex',
    flexDirection: 'row',
  },

  [`& .${classes.desktopTitle}`]: {
    marginLeft: '1rem',
    cursor: 'pointer',
    fontWeight: 550,
    color: theme.palette.primary.main,
    '&:hover': {
      color: '#fff',
    }
  },

  [`& .${classes.accountCircleIconDesktop}`]: {
    width: '35px',
    height: '35px',
    cursor: 'pointer'
  },

  [`& .${classes.buttonMenuHeight}`]: {
    height: '55px',
  },

  [`& .${classes.cursorPointer}`]: {
    cursor: 'pointer',
  },

  [`& .${classes.logout}`]: {
    position: "absolute",
    bottom: 0,
    width: '100%',
    borderTop: '1px solid rgba(255, 255, 255, 0.12)',
  },

  [`& .${classes.authButtonsContainer}`]: {
    display: 'flex',
  },

  [`& .${classes.connectbuttons}`]: {
    height: '30px',
    minWidth: '100px',
    marginRight: '1rem',
  },

  [`& .${classes.rightTopMenuDisplay}`]: {
    display: 'flex',
    alignItems: 'center',
  },

  [`& .${classes.buttonSize}`]: {
    width: '50px'
  }
}));

const drawerWidth = 240;

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide in={!trigger} direction="down">
      {children}
    </Slide>
  );
}

export default function PersistentDrawerLeft(props) {

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const theme = useTheme();
  const history = useNavigate();
  const { t } = useTranslation();
  const loggedIn = useSelector(state => state.user.loggedIn);
  const userAvatar = useSelector((state) => loggedIn && state.user.currentUser.accountDetails?.avatar)
  const [myAvatar, setMyAvatar] = useState();
  const { width } = useViewport();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const fetchAccountInfos = async () => {
    try {
      const thunk = await dispatch(getAccountInfo());
      if (thunk.meta.requestStatus === 'rejected')
        throw new Error('offline')
    } catch {
      enqueueSnackbar(t('toasts.unknownError'), { variant: 'error', });
    }
  }

  useEffect(() => {
    if (loggedIn) {
      fetchAccountInfos();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  useEffect(() => {
    setMyAvatar(userAvatar);
  }, [userAvatar])

  return (
    <Root className={classes.root}>
      {width <= 768 ?
        <HideOnScroll {...props}>
          <AppBar
            position="fixed"
            className={clsx(classes.appBar)}
          >
            <Toolbar className={classes.ToolbarRoot}>
              <div className="menu-logo" onClick={() => history('/')} />
              <div className={clsx(classes.rightMenuContainerPosition, classes.rightMenuContainerPositionMobile)}>
                {loggedIn ?
                  <div className={classes.rightTopMenuDisplay}>
                    {myAvatar && <Avatar src={myAvatar} alt="my Avatar" onClick={() => history('/account')} />}
                    {!myAvatar &&
                      <AccountCircleIcon
                        className={clsx(open && classes.hide, !open && classes.accountCircleIconDesktop)}
                        onClick={() => history('/account')}
                      />
                    }
                    <Button
                      ref={anchorRef}
                      aria-controls={open ? 'menu-list-grow' : undefined}
                      aria-haspopup="true"
                      className={classes.buttonMenuHeight}
                      onClick={handleToggle}
                    >
                      <ExpandMoreIcon />
                    </Button>
                    <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                      {({ TransitionProps, placement }) => (
                        <Fade
                          {...TransitionProps}
                          style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                          <Paper className={classes.popperStyle}>
                            <ClickAwayListener onClickAway={handleClose}>
                              <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                <MenuItem
                                  onClick={(e) => {
                                    handleClose(e);
                                    history('/account');
                                  }}
                                >
                                  <PersonIcon style={{ marginRight: '12px' }} />
                                  <Typography className={classes.popperTextStyle} variant="h4">{t('topMenu.profile')}</Typography>
                                </MenuItem>
                                {/* <MenuItem>
                                  <PersonIcon style={{ marginRight: '12px' }} />
                                  <Typography variant="h4">{t('topMenu.campaigns')}</Typography>
                                </MenuItem> */}
                                <MenuItem
                                  onClick={(e) => {
                                    handleClose(e);
                                    enqueueSnackbar(t('toasts.logout'), { variant: 'success', autoHideDuration: 3000 });
                                    dispatch(logout())
                                    history('/')
                                  }}
                                >
                                  <PowerSettingsNewIcon style={{ marginRight: '12px' }} />
                                  <Typography className={classes.popperTextStyle} variant="h4">{t("topMenu.logout")}</Typography>
                                </MenuItem>
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Fade>
                      )}
                    </Popper>
                  </div>
                  :
                  <div className={classes.authButtonsContainer}>
                    <Button
                      variant="contained"
                      className={classes.connectbuttons}
                      color="primary"
                      onClick={() => dispatch(openModal('login'))}
                    >
                      {t("home.connect")}
                    </Button>
                    <Button
                      variant="contained"
                      className={classes.connectbuttons}
                      color="primary"
                      onClick={() => dispatch(openModal(('register')))}
                    >
                      {t("home.register")}
                    </Button>
                  </div>
                }
              </div>
            </Toolbar>
          </AppBar>
        </HideOnScroll>
        :
        <AppBar
          enableColorOnDark
          position="fixed"
          className={classes.appBar}
        >
          <Toolbar>
            <div className="menu-logo" onClick={() => history('/')} />
            <div
              className={clsx(loggedIn ? classes.rightMenuContainer : '', classes.rightMenuContainerPosition, classes.rightMenuContainerPositionDesktop)}>
              {loggedIn ?
                <div className={classes.rightTopMenuDisplay}>
                  {myAvatar && <Avatar src={myAvatar} alt="my Avatar" onClick={() => history('/account')}
                    className={classes.cursorPointer} />}
                  {!myAvatar &&
                    <AccountCircleIcon
                      className={clsx(open && classes.hide, !open && classes.accountCircleIconDesktop)}
                      onClick={() => history('/account')}
                    />
                  }
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="end"
                    onClick={() => setOpen(true)}
                    className={clsx(classes.buttonSize, open && classes.hide)}
                    size="large">
                    <MenuIcon />
                  </IconButton>
                  <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    anchor="right"
                    open={open}
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                  >
                    <div className={classes.drawerHeader}>
                      <IconButton onClick={() => setOpen(false)} style={{ width: '50px' }} size="large">
                        {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                      </IconButton>
                    </div>
                    <Divider />
                    <List>
                      <ListItem button key="profile">
                        <ListItemIcon onClick={() => {
                          history('/account');
                          setOpen(false);
                        }}>
                          {myAvatar && <Avatar src={myAvatar} alt="my Avatar" className={classes.cursorPointer} />}
                          {!myAvatar &&
                            <AccountCircleIcon
                              className={clsx(open && classes.hide, !open && classes.accountCircleIconDesktop)}
                            />
                          }
                        </ListItemIcon>
                        <ListItemText
                          primary={t('topMenu.profile')}
                          onClick={() => {
                            history('/account');
                            setOpen(false);
                          }}
                        />
                      </ListItem>
                      {/* <ListItem button key="campaigns">
                        <ListItemIcon>
                          <GroupWork />
                        </ListItemIcon>
                        <ListItemText
                          primary={t('topMenu.campaigns')}
                        />
                      </ListItem> */}
                    </List>
                    <List className={classes.logout}>
                      <ListItem button key="disconnect">
                        <ListItemIcon><PowerSettingsNewIcon /></ListItemIcon>
                        <ListItemText
                          primary={t('topMenu.logout')}
                          onClick={(e) => {
                            handleClose(e);
                            enqueueSnackbar(t('toasts.logout'), { variant: 'success', autoHideDuration: 3000 });
                            dispatch(logout())
                            history('/')
                          }}
                        />
                      </ListItem>
                    </List>
                  </Drawer>
                </div>
                :
                <div className={classes.authButtonsContainer}>
                  <Button
                    variant="contained"
                    className={classes.connectbuttons}
                    color="primary"
                    onClick={() => dispatch(openModal(('login')))}
                  >
                    {t("home.connect")}
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.connectbuttons}
                    color="primary"
                    onClick={() => dispatch(openModal(('register')))}
                  >
                    {t("home.register")}
                  </Button>
                </div>
              }
            </div>
          </Toolbar>
        </AppBar>
      }
    </Root>
  );
}
