import React from "react";
import {throttle} from './throttle';

const viewportContext = React.createContext({});

export const ViewportProvider = ({children}) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);

  const handleWindowResize = throttle(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }, 1000);

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <viewportContext.Provider value={{width, height}}>
      {children}
    </viewportContext.Provider>
  );
};

export const useViewport = () => {
  const {width, height} = React.useContext(viewportContext);
  return {width, height};
};
