import { styled } from '@mui/material';
import React from 'react';
import Carousel from 'react-material-ui-carousel';

const PREFIX = 'HeadersDisplayer';

const classes = {
  root: `${PREFIX}-root`,
  headerStyleContainer: `${PREFIX}-headerStyleContainer`,
  headerStyle: `${PREFIX}-headerStyle`,
};

const HeadersDisplayerContainer = styled('div')(({ theme }) => {
  return ({
    [`& .${classes.root}`]: {
      [theme.breakpoints.up('sm')]: {
        position: 'relative',
        left: '50%',
        transform: 'translate(-50%)',
      },
      marginBottom: '2rem'
    },
    [`& .${classes.headerStyleContainer}`]: {
      width: '100%',
      height: '350px',
      display: 'flex',
      justifyContent: 'center',
      [`${theme.breakpoints.up('md')}`]: {
        height: '600px',
      },
      [`${theme.breakpoints.between('sm', 'lg')}`]: {
        maxWidth: '50%',
        margin: '0 auto',
      }
    },
    [`& .${classes.headerStyle}`]: {
      [theme.breakpoints.down('md')]: {
        width: '100%',
        objectFit: 'cover',
      },
      height: '100%',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  })
});

const HeadersDisplayer = (props) => {
  return (
    <HeadersDisplayerContainer>
      <div className={classes.root}>
        {props.headerUrls.length > 0 &&
          <Carousel
            indicatorIconButtonProps={{
              style: {
                width: '15px',
              }
            }}
          >
            {props.headerUrls.map((item, index) =>
            (
              <div
                className={classes.headerStyleContainer}
                key={`header-url-${index + 1}`}
              >
                <img
                  src={item.url}
                  alt={`header-url-${index + 1}`}
                  className={classes.headerStyle}
                />
              </div>
            )
            )}
          </Carousel>
        }
      </div>
    </HeadersDisplayerContainer>
  )
}

export default HeadersDisplayer;