export const campaign = {
  /**
   *
   */
  CAMPAIGN_REQUEST: 'CAMPAIGN_REQUEST',
  CAMPAIGN_SUCCESS: 'CAMPAIGN_SUCCESS',
  CAMPAIGN_FAILURE: 'CAMPAIGN_FAILURE',
  CAMPAIGN_RESET: 'CAMPAIGN_RESET',

  /**
   *
   */
  CAMPAIGN_USER_SAVE_FILE: 'CAMPAIGN_USER_SAVE_FILE',
  CAMPAIGN_USER_SAVE_MESSAGE: 'CAMPAIGN_USER_SAVE_MESSAGE',
  CAMPAIGN_USER_CLEAN_USERDATA: 'CAMPAIGN_USER_CLEAN_USERDATA',
  CAMPAIGN_USER_SAVE_FORM: 'CAMPAIGN_USER_SAVE_FORM',
  CAMPAIGN_USER_SAVE_CGU: 'CAMPAIGN_USER_SAVE_CGU',
  CAMPAIGN_USER_SAVE_SPREAD_DATA: 'CAMPAIGN_USER_SAVE_SPREAD_DATA',
  CAMPAIGN_USER_SAVE_NEWSLETTER: 'CAMPAIGN_USER_SAVE_NEWSLETTER',
  CAMPAIGN_USER_RESET: 'CAMPAIGN_USER_RESET',

  /**
   *
   */
  CAMPAIGN_USER_SAVE_REQUEST: 'CAMPAIGN_USER_SAVE_REQUEST',
  CAMPAIGN_USER_SAVE_SUCCESS: 'CAMPAIGN_USER_SAVE_SUCCESS',
  CAMPAIGN_USER_SAVE_FAILURE: 'CAMPAIGN_USER_SAVE_FAILURE',
  CAMPAIGN_USER_ALREADY_EXISTS: 'CAMPAIGN_USER_ALREADY_EXISTS',

  /**
   *
   */
  CAMPAIGN_USER_VIP_REQUEST: 'CAMPAIGN_USER_VIP_REQUEST',
  CAMPAIGN_USER_VIP_SUCCESS: 'CAMPAIGN_USER_VIP_SUCCESS',
  CAMPAIGN_USER_VIP_FAILURE: 'CAMPAIGN_USER_VIP_FAILURE',
};
