import React from 'react';
import './header.css';

const Header = ({ imgHeader }) => {
  return (
    <div className="header-container">
      <div style={{
        width: '100%',
        height: '100%',
        backgroundImage: `url('${imgHeader}')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }}>
      </div>
    </div>
  )
}

export default Header