import React from "react";
import {useTranslation} from "react-i18next";
import { styled } from '@mui/material/styles';

const PREFIX = 'Presentation';

const classes = {
  mainContainer: `${PREFIX}-mainContainer`,
  title: `${PREFIX}-title`,
  textColor: `${PREFIX}-textColor`,
  descriptionStyle: `${PREFIX}-descriptionStyle`
};

const PresentationRoot = styled('div')(({theme}) => {
  return ({
  [`& .${classes.mainContainer}`]: {
    maxHeight: '100%',
    overflowY: 'auto'
  },
  [`& .${classes.title}`]: {
    color: 'white',
    textAlign: 'center',
  },
  [`& .${classes.textColor}`]: {
    color: '#CEB673'
  },
  [`& .${classes.descriptionStyle}`]: {
    margin: '2rem',
    textAlign: 'justify',
  },
})
});

export function Presentation(props) {
  const isVip = window.location.pathname.indexOf("vip") >= 0;
  const {t} = useTranslation();
  return (
    <PresentationRoot>
      <div className={classes.mainContainer}>
        <h2 className={classes.title}>
          <div>{isVip ? t('landing.welcomeVip') : t('landing.welcome')}</div>
          <div><b className={classes.textColor}>{props.name}</b></div>
        </h2>
        <div className={classes.descriptionStyle} dangerouslySetInnerHTML={{__html: props.description}}/>
      </div>
    </PresentationRoot>
  );
}
