import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { useDispatch, useSelector } from "react-redux";
import { saveForm } from "../../reducers/campaigns/campaignsSlice";
import { styled } from "@mui/material";

const PREFIX = "userForm";

const classes = {
  formInputsContainer: `${PREFIX}-formInputsContainer`,
  formControlStyle: `${PREFIX}-formControlStyle`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.formInputsContainer}`]: {
    textAlign: "left",
    textAlignLast: "left",
    color: "#fff",
    textAlign: "left",
    borderRadius: "0",
    paddingRight: "2%",
  },
  [`& .${classes.formControlStyle}`]: {
    width: "100%",
    margin: "0.3rem",
  },
}));

export default function UserForm(props) {
  const { t } = useTranslation();
  const [data, setData] = useState({});
  const dataRedux = useSelector((state) => state.campaign.userData);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.user) {
      setData(props.user);
      dispatch(saveForm({ email: props.user.email }));
    }
  }, [props.user]);

  useEffect(() => {
    setData({ ...props.user, ...data, ...dataRedux });
  }, [dataRedux]);

  useEffect(() => {
    checkFields();
  });

  function handleChange(e) {
    const { name, value } = e.target;
    setData((data) => ({ ...data, [name]: value }));
    dispatch(saveForm({ [name]: value }));
  }

  function validateEmail(email) {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    ); //eslint-disable-line
  }

  function checkFields() {
    if (
      data.lastname &&
      data.lastname.length > 0 &&
      data.firstname &&
      data.firstname.length > 0 &&
      validateEmail(data.email)
    ) {
      return props.isFormOk(true);
    }
    return props.isFormOk(false);
  }

  return (
    <Root>
      <div className={classes.formInputsContainer}>
        <FormControl variant="standard" className={classes.formControlStyle}>
          <InputLabel htmlFor="lastname">{t("landing.form.name")}</InputLabel>
          <Input
            variant="outlined"
            label={t("landing.form.lastname")}
            onChange={handleChange}
            name="lastname"
            id="lastname"
            value={data.lastname || ""}
          />
        </FormControl>
        <FormControl variant="standard" className={classes.formControlStyle}>
          <InputLabel htmlFor="firstname">
            {t("landing.form.surname")}
          </InputLabel>
          <Input
            variant="outlined"
            onChange={handleChange}
            name="firstname"
            id="firstname"
            value={data.firstname || ""}
          />
        </FormControl>
        <FormControl variant="standard" className={classes.formControlStyle}>
          <InputLabel htmlFor="email">{t("landing.form.email")}</InputLabel>
          <Input
            variant="outlined"
            onChange={handleChange}
            id="email"
            name="email"
            value={data.email || ""}
            disabled={true}
          />
        </FormControl>
      </div>
    </Root>
  );
}
